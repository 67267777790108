import React, {useEffect,useState} from 'react'
 //import Headerpart from '../Comonpart/Headerpart';
 import { useNavigate } from 'react-router-dom';
 import axios from 'axios';
  import Headerpart from '../Comonpart/Headerpart';
  const basurl=process.env.REACT_APP_BASE_URL;
export default function Addnewtimesheet() {
    const navigate = useNavigate();
   
    const Crateid=localStorage.getItem("userAtutc");
    const [fetchproject,setFetchproject]=useState('');

    const [projectid,setpPojectid]=useState('');
    const [status,setStatus]=useState('');
    const [theading,setTheading]=useState('');
    const [startdate,setStartdate]=useState('');
    const [enddate,setEnddate]=useState('');
    const [file, setFile] = useState()

    const [sttusesdata,setSttusesdata]=useState('');
    
  function fechststuss()
  {
    axios.get(basurl+`/api/Fetchststus`)
    .then(res=>{
        setSttusesdata(res.data); 
    })
    .catch(error=> console.log(error));

    
  }
      function fetchprojectdata()
     {
       axios.get(basurl+`/api/fetchproject/${Crateid}`)
        .then(res=>{
            setFetchproject(res.data); 
        })
        .catch(error=> console.log(error));

     }
  useEffect(() => {
    if (localStorage.getItem("user")== null) {
      navigate('/');
    }
    fetchprojectdata(); 
    fechststuss();
  }, []);
//   const handleChange = event => {
//     setName(event.target.value);
//   };
  const handelsubmit=()=>{ 


          
    const formdata= new FormData();


    formdata.append('file',file)
    formdata.append('projectid',projectid)
    formdata.append('status',status)

    formdata.append('theading',theading)
    formdata.append('startdate',startdate)
    formdata.append('enddate',enddate)
    formdata.append('Crateid',Crateid)



        // axios.post(basurl+'/api/Newtimesheet',{projectid,status,theading,startdate,enddate,Crateid,file}) 
        axios.post(basurl+'/api/Newtimesheet',formdata) 
         .then(res=>{
          
             if(res.data>0)
             {
                 alert("Succes full Add New Project"); 
                 theading('');
                 startdate('');
                 enddate('');
               //  projectid('');
                 //fetchprojectdata();
                
             }

             else
             {

                 alert("Soming Mesiing ")
             }

         })
         .catch(err=>console.log(err));

    }
  return (
    <>
    <Headerpart/>
    <main id="main" className="main">

<section className="section">
    <div className="row">
        <div className="col-lg-12">

            <div className="card">
                <div className="card-body">
                <div class="row">
        
           
        <div class="col-md-6">
                <div class="form-group">
                    <label for="projectName">Project Name:</label>
                    <select class="form-select" id="projectName"   onChange={e=>setpPojectid(e.target.value)}>
                        <option value={0}>--Select Project --</option>
                    {fetchproject && fetchproject.map((item ,i) => (
                        <option value={item.id}>{item.Project_name}</option>
                        ))}
                       
                    </select>
                </div>
                </div>
                <div class="col-md-6">
                <div class="form-group">
                    <label for="heading" > Status :</label>
                    <select class="form-select" id="projectName"   onChange={e=>setStatus(e.target.value)}>
                        <option value={0}>--Select Status --</option>
                    {sttusesdata && sttusesdata.map((item ,i) => (
                        <option value={item.id}>{item.name}</option>
                        ))}
                       
                    </select>
                </div>
                        </div>

                        <div class="col-md-6">
                <div class="form-group">
                    <label for="startDate">Start Date:</label>
                    <input type="date" class="form-control" id="startDate" onChange={e=>setStartdate(e.target.value)}  value={startdate}  required/>
                </div>
                        </div>
                        <div class="col-md-6">
                <div class="form-group">
                    <label for="startTime">End  Date:</label>
                    <input type="date" class="form-control" id="startTime" onChange={e=>setEnddate(e.target.value)}  value={enddate} required/>
                </div>
                        </div>


                        <div class="col-md-6">
                <div class="form-group">
                    <label for="remarks">Heading</label>
                    <textarea class="form-control" id="remarks" rows="4" required  onChange={e=>setTheading(e.target.value)}  ></textarea>
                </div>
            
        </div>

        
        <div class="col-md-6">
                <div class="form-group">
                    <label for="remarks">File</label>
                    <input type='file' class="form-control" onChange={(e) => setFile(e.target.files[0])}/>
                   
                </div>
            
        </div>
         
        <div class="col-md-6 p-lg-5">
                    <button type="submit" class="btn btn-primary" onClick={handelsubmit}>Submit</button>
                </div>
            </div> 

         
    </div>
                   
                </div>
            </div>
          
          

        </div>

    
</section>

    </main>
    </>
  )
}
