import React, {useEffect,useState} from 'react'
 //import Headerpart from '../Comonpart/Headerpart';
 import { useNavigate } from 'react-router-dom';
 import axios from 'axios';
  import Headerpart from '../Comonpart/Headerpart';
  const basurl=process.env.REACT_APP_BASE_URL;
export default function Projectseting() {
    const navigate = useNavigate();
    const [name,setName]=useState('');
    const Crateid=localStorage.getItem("userAtutc");
    const [fetchproject,setFetchproject]=useState('');

      function fetchprojectdata()
     {
       axios.get(basurl+`/api/Getproject/${Crateid}`)
      //axios.get(`/api/fechdatbycompnay/${Crateid}`)

        .then(res=>{
            setFetchproject(res.data);
    
        })
        .catch(error=> console.log(error));

     }
  useEffect(() => {
    //const loggedInUser = localStorage.getItem("user");
    if (localStorage.getItem("user")== null) {
      //console.log(loggedInUser);
      navigate('/');
    }
    fetchprojectdata();
   
  }, []);

  const handleChange = event => {
    setName(event.target.value);
  };

  const handelsubmit=()=>{ 
       
        axios.post(basurl+'/api/Addproject',{name,Crateid}) 
         .then(res=>{
          
             if(res.data>0)
             {
                 alert("Succes full Add New Project"); 
                 setName('');
                 fetchprojectdata();
                
             }

             else
             {

                 alert("Soming Mesiing ")
             }

         })
         .catch(err=>console.log(err));

    }
  return (
    <>
    <Headerpart/>
    <main id="main" className="main">

<section className="section">
    <div className="row">
        <div className="col-lg-12">

            <div className="card">
                <div className="card-body">
                    <h5 className="card-title">Manage Your Project </h5>

                   
                   
                        <div className="row mb-3">
                            <label for="inputEmail3" className="col-sm-2 col-form-label">Project  Name</label>
                            <div className="col-sm-10">
                                <input type="text" className="form-control" id="inputText"  onChange={handleChange}
        value={name}  />
                            </div>
                        </div>
                      
                        <div className="text-center">

                        <button type="submit" onClick={handelsubmit} className="btn btn-primary">Submit</button>
                           
                        </div>
                   
                </div>
            </div>
            <table className="table datatable">
                <thead>

              
                  <tr>
                  <th>
                    S.N
                    </th>
                    <th>
                     Project Name
                    </th>
                    <th>Date.</th>
                   
                  </tr>
                </thead>
                <tbody>
                {fetchproject && fetchproject.map((item ,i) => (
                  <tr>
                       <td>{i + 1}</td>
                    <td>{item.Project_name}</td>
                    <td>  1 </td>
                    
                  </tr>
                  ))}
                </tbody>
              </table>
          
          

        </div>

      
    </div>
</section>

    </main>
    </>
  )
}
