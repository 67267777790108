import React,{useEffect,useState} from 'react'
import Headerpart from './Comonpart/Headerpart'
import { useNavigate } from 'react-router-dom';
 import axios from 'axios';
 import FormWizard from "react-form-wizard-component";
import "react-form-wizard-component/dist/style.css";
import $ from "jquery";
const basurl=process.env.REACT_APP_BASE_URL;

export default function Addemployee() {
    const [fordata,setFormdat]=useState({

        "name":'',
        "email":'',
        "conatctnumabe":'',
        "address":'',
        "mothernameitle":'',
        "fatername":'',
        "dob":'',
        "joindate":'',
        "emptype":'',
        "postion":'',
        "pencard":'',
        "adrdhrcard":'',
        "title":'',
        "gender":'',
       " Addres_type":'',
        "country":'',
        "citynane":'',
        "stattesname":'',
        "District":'',
        "Responsiblep":'',
        "co":'',
        "house":'',
        "floor":'',
        "Building":'',
       "Landmark":'',
       "relation_typ":'',
        "f_title":'',
        "f_Name":'',
        "m_Name":'',
        "l_name":'',
        "f_dob":'',
        "f_contact":'',
        "f_gender":'',
        "Education_Type":'',
        "Edo_Type":'',
        "Course_Name":'',
        "UNI_Name":'',
        "INS_Type":'',
        "Devision":'',
        "Percentage":'',
        "CGPA":'',
        "Passing_year":'',
        "EMP_Id":'',
        "trust":'',
        "fund_no":'',
        "position_trust":'',
        "type_of_inst":'',
        "Etime":'',
        "Weekday":'',
        "Stime":''



    })
    const navigate = useNavigate();
    // const [name,setName]=useState('');
    //  const [email,setEmail]=useState('');
    //  const [conatctnumabe,setConatctnumabe]=useState('');
    //  const [address,setAddress]=useState('');
    //  const [mothernameitle,setMothername]=useState('');
    //  const [fatername,setFatername]=useState('');
    //  const [dob,setDob]=useState('');

    //  const [joindate,setJoindate]=useState('');
    //  const [emptype,setEmptype]=useState('');
    //  const [postion,setPostion]=useState('');

    //  const [pencard,setPen]=useState('');
    //  const [adrdhrcard,setAdrdhrcard]=useState('');
    //  const [title,setTitle]=useState('');
    //  const [gender,setGender]=useState('');
    

     const [fetchgender,setFetchgender]=useState('');
     const [fetchtitle,setFetchtitle]=useState('');
      const [emplyerrtype,setEmplyerrtype]=useState('');
      const [basicpay,setBasicpay]=useState('');
     
      const [countery,setCountery]=useState('');
      const [states,setStates]=useState('');
      const [city,setCity]=useState('');
      const [rolse,setRolse]=useState('');
      const userrol='EMP';

      function Fetchtitle()
      {
         axios.get(basurl+`/api/Fetchtitle/`)
         .then(res=>{
             console.log(res.data);
             setFetchtitle(res.data); 
         })
         .catch(error=> console.log(error));
 
      }
      const Crateid=localStorage.getItem("userAtutc");

      function Employeetype()
    {

        axios.get(basurl+`/api/getallEmployeeType/${Crateid}`)
        .then(res=>{
    
            setEmplyerrtype(res.data.roles);
            
    
        })
        .catch(error=> console.log(error));

    }

      function FetchGender()
      {
         axios.get(basurl+`/api/getgender/`)
         .then(res=>{
             console.log(res.data);
             setFetchgender(res.data); 
         })
         .catch(error=> console.log(error));
 
      }
 

      function fetchempltype()
      {
  
          axios.get(basurl+`/api/getallrole/${Crateid}`)
          .then(res=>{
      
            setRolse(res.data.roles);
              
      
          })
          .catch(error=> console.log(error));
  
      }



      function Basicpay()
      {
  
          axios.get(basurl+`/api/Basicpaydata/${Crateid}`)
          .then(res=>{
      
            setBasicpay(res.data);
              
      
          })
          .catch(error=> console.log(error));
  
      }


      
      function Counterydate()
      {
  
        axios.get(basurl+`/api/getAllCountrys/${Crateid}`)
          .then(res=>{
      
            setCountery(res.data);
              
      
          })
          .catch(error=> console.log(error));
  
      }

      function statedata()
      {
  
        axios.get(basurl+`/api/getAllStates/${Crateid}`)
          .then(res=>{
      
            setStates(res.data);
              
      
          })
          .catch(error=> console.log(error));
  
      }

      function citydata()
      {
  
        axios.get(basurl+`/api/getAllCitys/${Crateid}`)
          .then(res=>{
      
            setCity(res.data);
              
      
          })
          .catch(error=> console.log(error));
  
      }
  
  
  

  useEffect(() => {
    //const loggedInUser = localStorage.getItem("user");
    if (localStorage.getItem("user")== null) {
      //console.log(loggedInUser);
      navigate('/');
    }
    Fetchtitle();
    FetchGender();
    Employeetype();
    fetchempltype();
    Basicpay();
    Counterydate();
    statedata();
    citydata();
   
  }, []);


   const handelinputchnage=(e)=>{
    setFormdat({
      ...fordata,
      [e.target.name] :e.target.value
    })
   }
 
  const handelsubmit=()=>{

    const Crateid=localStorage.getItem("userAtutc");



    let books_items = [];

    let Dection_basicpay = [];
   // for add books item
   let books_total_items = $("#books-table-body tr").length
   for (let i = 0; i < books_total_items; i++) {
     let books_item = {}
     $(`#books-table-body tr:nth-child(${i + 1}) :input`).each(function () {
       if (this.value) {
         books_item[this.name] = this.value;
       }
     });
     if (Object.keys(books_item).length > 0) {
       books_items.push(books_item)
     }
   }

   //Basic Pay dedection 


   let basicpay_dection = $("#books-table-body tr").length
   for (let i = 0; i < basicpay_dection; i++) {
     let Dection_basicpaydate = {}
     $(`#didiction-table-body tr:nth-child(${i + 1}) :input`).each(function () {
       if (this.value) {
        Dection_basicpaydate[this.name] = this.value;
       }
     });
     if (Object.keys(Dection_basicpaydate).length > 0) {
        Dection_basicpay.push(Dection_basicpaydate)
     }
   }

   // console.log(books_items);
   //axios.defaults.withCredentials=true;
   
     axios.post(basurl+'/api/Createcompay',{fordata,Crateid,userrol,books_items,Dection_basicpay})
      
     .then(res=>{
      
         if(res.data>0)
         {
             alert("Succes full Add the data"); 
            
         }

         else
         {

             alert("Soming Mesiing ")
         }

     })
     .catch(err=>console.log(err));



}

//Add the validation 

const [firstTabInput, setFirstTabInput] = React.useState("");
const handleComplete = () => {
  console.log("Form completed!");
  // Handle form completion logic here
  
  

  handelsubmit();
};
// check validate tab
const checkValidateTab = () => {
//  console.log(firstTabInput);
  
//   if (name === "") {
  
//     return false;
//   }

//    if(conatctnumabe==="")
//    {
//      return false;
//    }
  
  return true;
};
// error messages
const errorMessages = () => {
  // you can add alert or console.log or any thing you want
  alert("Please fill in the required fields");
};




const addBooksHandler = () => {

    let items2 = basicpay?.map((item, i) => {
          return (
            `<option key=${i} value=${item?.id}>${item?.Pay_name}</option>`
         )
         })
    
    $("tbody#books-table-body").append(`<tr>
    
      <td>
      <select class="form-select company-select-option" name="basictype">
      <option value=''>Select</option>
      ${items2}
      </select>
      </td>
      <td>
        <input
          class="form-control"
          min="0"
          step=".01"
          type="number"
          name="Amount"
          max='100' />
      </td>
    <td>
    <button class="btn btn-primary">Delete</button>
    </td>
    </tr>`)
   
    $("tbody#books-table-body tr td:last-child button").on("click", function (e) {
        deleteItemHandler(e)
      });
   
  }

  const deleteItemHandler = (e) => {
    let tr = e.target.parentElement.parentElement
    tr.remove()
  }



  const deletededection = (e) => {
    let tr = e.target.parentElement.parentElement
    tr.remove()
  }

  



  const adddedaction = () => {

    let items2 = basicpay?.map((item, i) => {
          return (
            `<option key=${i} value=${item?.id}>${item?.Pay_name}</option>`
         )
         })
    
    $("tbody#didiction-table-body").append(`<tr>
    
      <td>
      <select class="form-select company-select-option" name="basictype">
      <option value=''>Select</option>
      ${items2}
      </select>
      </td>
      <td>
        <input
          class="form-control"
          min="0"
          step=".01"
          type="number"
          name="Amount"
          max='100' />
      </td>
    <td>
    <button class="btn btn-primary">Delete</button>
    </td>
    </tr>`)
   
    $("tbody#didiction-table-body tr td:last-child button").on("click", function (e) {
        deletededection(e)
      });
   
  }

//End thwe validation 
  return (
    <>
    <Headerpart/>
    <main id="main" className="main">

<section className="section">
    <div className="row">
        <div className="col-lg-12">

            <div className="card">
                <div className="card-body">
                    <h5 className="card-title">Add New  Employee   </h5>


                    <FormWizard onComplete={handleComplete}>
        <FormWizard.TabContent title="Personal Details" icon="ti-user">


            
    

        <div className="row mb-3">
                            <label for="inputEmail3" className="col-sm-2 col-form-label">  Title 
        </label>
                            <div className="col-sm-3">

                                <select  className='form-select' name='title'  onChange={handelinputchnage}>

                                    <option>--Select Title--</option>
                                    {fetchtitle && fetchtitle.map((item ,i) => (

                                    <option value={item.id}>{item.name}</option>
                                        
                                        
                                        ))}
                                </select>         
                                            </div>



                                            <label for="inputEmail3" className="col-sm-2 col-form-label">  Gender 
        </label>
                            <div className="col-sm-3">

                                                <select  className='form-select'  name='gender'  onChange={handelinputchnage}>

                    <option>--Select Title--</option>
                    {fetchgender && fetchgender.map((items ,i) => (

                    <option value={items.id}>{items.gender_name}</option>
                        
                        
                        ))}
                    </select>       
                                            </div>





                            </div>




  


                        

        <div className="row mb-3">
                            <label for="inputEmail3" className="col-sm-2 col-form-label">  Name  <span
              style={{ color: "red", fontSize: "10px", fontWeight: "bold" }}
            >
              *
            </span></label>
                            <div className="col-sm-3">

                        
                                <input type="text" className="form-control" id="inputText"  name='name'  value={fordata.name} onChange={handelinputchnage} />
                            </div>
                            <label for="inputEmail3" className="col-sm-2 col-form-label">Email </label>
                            <div className="col-sm-3">
                           
                                <input type="email" className="form-control" id="inputEmail"  name='email' value={fordata.email} onChange={handelinputchnage} />
                            </div>
                        </div>
                       
                        <div className="row mb-3">
                            <label for="inputPassword3" className="col-sm-2 col-form-label">Contact Number  <span
              style={{ color: "red", fontSize: "20px", fontWeight: "bold" }}
            >
              *
            </span></label>
                            <div className="col-sm-3">
                                <input type="number"  className="form-control" id="inputPassword"  name='conatctnumabe' value={fordata.conatctnumabe}   onChange={handelinputchnage} />
                            </div>

                            <label for="inputPassword3" className="col-sm-2 col-form-label">Pen Card </label>
                            
                          
                            <div className="col-sm-3">
                                <input type="text"  className="form-control" id="inputPassword"  name='pencard' value={fordata.pencard} onChange={handelinputchnage}/>
                            
                        </div>

                            
                        </div>

                        
                        <div className="row mb-3">
                            <label for="inputPassword3" className="col-sm-2 col-form-label">Father Name </label>
                            <div className="col-sm-3">
                                <input type="text"  className="form-control" id="inputPassword"  name='fatername' value={fordata.fatername} onChange={handelinputchnage}/>
                            </div>

                            <label for="inputPassword3" className="col-sm-2 col-form-label">Monther Name </label>

                            <div className="col-sm-3">
                                <input type="text"  className="form-control" id="inputPassword"  name='mothernameitle'  value={fordata.mothernameitle}  onChange={handelinputchnage}/>
                            </div>
                        </div>


                        
                        <div className="row mb-3">
                            <label for="inputPassword3" className="col-sm-2 col-form-label">Date Of Birth </label>
                            <div className="col-sm-3">
                                <input type="date"  className="form-control" id="inputPassword" value={fordata.dob}   name='dob'  onChange={handelinputchnage}/>
                            </div>

                            <label for="inputPassword3" className="col-sm-2 col-form-label">Date of Joing</label>
                            <div className="col-sm-3">
                                <input type="date"  className="form-control" id="inputPassword"  value={fordata.joindate}  name='joindate' onChange={handelinputchnage}/>
                            </div>
                        </div>


                        <div className="row mb-3">
                            <label for="inputEmail3" className="col-sm-2 col-form-label">  Employee Type 
        </label>
                            <div className="col-sm-3">

                                <select  className='form-select'  name='emptype'  onChange={handelinputchnage}>

                                    <option>--Employee Type--</option>
                                    {emplyerrtype && emplyerrtype.map((item ,i) => (

                                    <option value={item.id}>{item.name}</option>
                                        
                                        
                                        ))}
                                </select>         
                                            </div>



                                            <label for="inputEmail3" className="col-sm-2 col-form-label">  Rols 
        </label>
                            <div className="col-sm-3">

                                <select  className='form-select'  name='postion'  onChange={handelinputchnage}>

                    <option>--Rols Title--</option>
                    {rolse && rolse.map((items ,i) => (

                    <option value={items.id}>{items.name}</option>
                        
                        
                        ))}
                    </select>       
                                            </div>





                            </div>




                          {/* Add the New Ite  */}


                          <div className="row mb-3">
                            <label for="inputEmail3" className="col-sm-2 col-form-label">  Employee Week Off 
        </label>
                            <div className="col-sm-3">

                                <select  className='form-select'  name='Weekday'  onChange={handelinputchnage}>

                                    <option>--Employee Type--</option>
                                    {emplyerrtype && emplyerrtype.map((item ,i) => (

                                    <option value={item.id}>{item.name}</option>
                                        
                                        
                                        ))}
                                </select>         
                                            </div>



                                            <label for="inputEmail3" className="col-sm-2 col-form-label">  Shift Timings  Start
        </label>
                            <div className="col-sm-3">

                              <input type='time' className='form-control' value={fordata.Stime}  name='Stime' onChange={handelinputchnage}></input>

                                   
                                            </div>





                            </div>



                                
                            <div className="row mb-3">
                        



                                            <label for="inputEmail3" className="col-sm-2 col-form-label">  Shift  End Timings   
        </label>
                            <div className="col-sm-3">

                              <input type='time' className='form-control'  value={fordata.Etime}  name='Etime' onChange={handelinputchnage}></input>

                                   
                                            </div>





                            </div>










                        {/* <div className="text-center">

                        <button type="submit" onClick={handelsubmit} className="btn btn-primary">Submit</button>
                           
                        </div> */}
          
          <br />
          
        </FormWizard.TabContent>
        {/* Tabs should be validated */}

        <FormWizard.TabContent
          title="Addresses"
          icon="ti-settings"
          isValid={checkValidateTab()}
          validationError={errorMessages}
        >
          

        <div className="row mb-3">
                            <label for="inputEmail3" className="col-sm-2 col-form-label">  Address Type  
        </label>
                            <div className="col-sm-3">

                                <select  className='form-select' name='Addres_type'  onChange={handelinputchnage}>

                                    <option>--Select Address Type --</option>
                                    <option value="PA">Permanent Address</option><option value="PP">Present Address</option>
                                    <option value="EA">Emergency Address</option>
                                    <option value="RA">Residential Address</option></select>
       
                                            </div>



                                            <label for="inputEmail3" className="col-sm-2 col-form-label">  Country 
        </label>
                            <div className="col-sm-3">

                                                <select  className='form-select' onChange={handelinputchnage}  name='country'  >

                    <option>--Select Title--</option>
                    {countery && countery.map((items ,i) => (

                    <option value={items.id}>{items.name}</option>
                        
                        
                        ))}
                    </select>       
                                            </div>





                            </div>




  


                        

        <div className="row mb-3">
                            <label for="inputEmail3" className="col-sm-2 col-form-label">  State   </label>
                            <div className="col-sm-3">
                            <select  className='form-select'  onChange={handelinputchnage}  name='stattesname'>

<option>--Select Title--</option>
{states && states.map((items ,i) => (

<option value={items.id}>{items.State_name}</option>
    
    
    ))}
</select>     

                        
                                </div>
                            <label for="inputEmail3" className="col-sm-2 col-form-label">City </label>
                            <div className="col-sm-3">

                            <select  className='form-select'  onChange={handelinputchnage}  name='citynane'>

<option>--Select Title--</option>
{city && city.map((items ,i) => (

<option value={items.id}>{items.City_name}</option>
    
    
    ))}
</select>  
                           
                                 </div>
                        </div>
                       
                        <div className="row mb-3">
                            <label for="inputPassword3" className="col-sm-2 col-form-label">District  </label>
                            <div className="col-sm-3">
                                <input type="text"  className="form-control"  value={fordata.District}  name='District'   onChange={handelinputchnage} />
                            </div>

                            <label for="inputPassword3" className="col-sm-2 col-form-label">Responsible Person </label>
                            
                          
                            <div className="col-sm-3">
                                <input type="text"  className="form-control"  value={fordata.Responsiblep}  name='Responsiblep'  onChange={handelinputchnage}/>
                            
                        </div>

                            
                        </div>

                        
                        <div className="row mb-3">
                            <label for="inputPassword3" className="col-sm-2 col-form-label">C/o </label>
                            <div className="col-sm-3">
                                <input type="text"  className="form-control" value={fordata.co}  name='co'  onChange={handelinputchnage}/>
                            </div>

                            <label for="inputPassword3" className="col-sm-2 col-form-label">House No </label>

                            <div className="col-sm-3">
                                <input type="text"  className="form-control"  value={fordata.house}  name='house'   onChange={handelinputchnage}/>
                            </div>
                        </div>


                        
                        <div className="row mb-3">
                            <label for="inputPassword3" className="col-sm-2 col-form-label">Floor </label>
                            <div className="col-sm-3">
                                <input type="text"  className="form-control"   value={fordata.floor}  name='floor'  onChange={handelinputchnage}/>
                            </div>

                            <label for="inputPassword3" className="col-sm-2 col-form-label">Building</label>
                            <div className="col-sm-3">
                                <input type="text"  className="form-control"  value={fordata.Building}  name='Building'  onChange={handelinputchnage}/>
                            </div>
                        </div>


                        <div className="row mb-3">
                            <label for="inputEmail3" className="col-sm-2 col-form-label">  Additional 1 
        </label>
                            <div className="col-sm-3">

                                <textarea className="form-control"  value={fordata.address}  name='address'  onChange={handelinputchnage}></textarea>       
                                            </div>



                                            <label for="inputEmail3" className="col-sm-2 col-form-label">  Landmark 
        </label>
                            <div className="col-sm-3">

                                              <input type='text'  className='form-control' value={fordata.Landmark}  name='Landmark'  onChange={handelinputchnage}/>
                                            </div>





                            </div>
                        
                       

                    
        </FormWizard.TabContent>
       

        <FormWizard.TabContent
          title="Family Details"
          icon="ti-settings"
         
          isValid={checkValidateTab()}
          validationError={errorMessages}
        >

<div className="row mb-3">
                            <label for="inputEmail3" className="col-sm-2 col-form-label">  Relationship Type   
        </label>
                            <div className="col-sm-3">

                                <select  className='form-select' name='relation_typ' onChange={handelinputchnage}>


                                
                                    <option value="0">--Select--</option>
                                <option value="1">Child - 1</option><option value="14">Step Mother</option>
                                <option value="16">Child </option>
                                <option value="20">Brother</option><option value="21">Sister</option></select>

                               
                                            </div>



                                            <label for="inputEmail3" className="col-sm-2 col-form-label">  Title  
        </label>
                            <div className="col-sm-3">

                            <select  className='form-select' name='f_title'  onChange={handelinputchnage}>

<option>--Select Title--</option>
{fetchtitle && fetchtitle.map((item ,i) => (

<option value={item.id}>{item.name}</option>
    
    
    ))}
</select>  


                                            </div>





                            </div>




  


                        

        <div className="row mb-3">
                            <label for="inputEmail3" className="col-sm-2 col-form-label">  First Name   </label>
                            <div className="col-sm-3">
                                <input type='text' className='form-control'  value={fordata.f_Name}  name='f_Name' onChange={handelinputchnage}/>

                        
                                </div>
                            <label for="inputEmail3" className="col-sm-2 col-form-label">Middle Name </label>
                            <div className="col-sm-3">

                            <input type='text' className='form-control'   value={fordata.m_Name}  name='m_Name' onChange={handelinputchnage}/>
                           
                                 </div>
                        </div>
                       
                        <div className="row mb-3">
                            <label for="inputPassword3" className="col-sm-2 col-form-label">Last Name   </label>
                            <div className="col-sm-3">
                                <input type="text"  className="form-control"   value={fordata.l_name}  name='l_name' onChange={handelinputchnage} />
                            </div>

                            <label for="inputPassword3" className="col-sm-2 col-form-label">DOB </label>
                            
                          
                            <div className="col-sm-3">
                                <input type="date"  className="form-control" id="inputPassword"  value={fordata.f_dob}  name='f_dob' onChange={handelinputchnage}/>
                            
                        </div>

                            
                        </div>

                        
                        <div className="row mb-3">
                            <label for="inputPassword3" className="col-sm-2 col-form-label">Contact No </label>
                            <div className="col-sm-3">
                                <input type="text"  className="form-control" id="inputPassword" value={fordata.f_contact}  name='f_contact' onChange={handelinputchnage}/>
                            </div>

                            <label for="inputPassword3" className="col-sm-2 col-form-label">Gender  </label>

                            <div className="col-sm-3">

                            <select  className='form-select'  name='f_gender'  onChange={handelinputchnage}>

<option>--Select Title--</option>
{fetchgender && fetchgender.map((items ,i) => (

<option value={items.id}>{items.gender_name}</option>
    
    
    ))}
</select>    

                               
                            </div>
                        </div>


           
        </FormWizard.TabContent>
        <FormWizard.TabContent title="Academic Details" icon="ti-settings">
        <div className="row mb-3">
                            <label for="inputEmail3" className="col-sm-2 col-form-label">  Education Est.   
        </label>
                            <div className="col-sm-3">

                                <select  className='form-select' onChange={handelinputchnage}  name='Education_Type'>

                               
                                <option value="1">Tenth </option><option value="2">Intermediate</option><option value="3">Bachelor</option>
                                <option value="4">Master</option><option value="6">Professional</option>
                                <option value="14">assa</option><option value="17">sdsdds</option>
                                <option value="18">Post Graduation</option>
                                <option value="21">Deploma</option><option value="22">rdsdfggfds</option>
                                </select>
                                
                                  
                               
                                            </div>



                                            <label for="inputEmail3" className="col-sm-2 col-form-label">  Course Name  
        </label>
                            <div className="col-sm-3">

                            <input type='text' className='form-control'  value={fordata.Course_Name}  name='Course_Name' onChange={handelinputchnage}/>
                                                      </div>


                            </div>  

        <div className="row mb-3">
                            <label for="inputEmail3" className="col-sm-2 col-form-label">  Insitution/College Name    </label>
                            <div className="col-sm-3">
                                <input type='text' className='form-control ' value={fordata.UNI_Name}  name='UNI_Name' onChange={handelinputchnage}/>

                        
                                </div>
                            <label for="inputEmail3" className="col-sm-2 col-form-label">Type of Insitution </label>
                            <div className="col-sm-3">

                            <select  className='form-select'   name='Edo_Type' onChange={handelinputchnage}>

                            
                                <option value="">Select</option>
                            <option value="BO">Board</option>
                            <option value="UN">University</option>
                            <option value="SS">SSDASA</option>
                            <option value="DF">DFDS</option>
                            </select>

                                 </div>
                        </div>
                       
                        <div className="row mb-3">
                            <label for="inputPassword3" className="col-sm-2 col-form-label">Division   </label>
                            <div className="col-sm-3">
                                <input type="text"  className="form-control"   value={fordata.Devision}  name='Devision'  onChange={handelinputchnage} />
                            </div>

                            <label for="inputPassword3" className="col-sm-2 col-form-label">Percentage  </label>
                            
                          
                            <div className="col-sm-3">
                                <input type="text"  className="form-control"  value={fordata.Percentage}  name='Percentage' onChange={handelinputchnage}/>
                            
                        </div>

                            
                        </div>

                        
                        <div className="row mb-3">
                            <label for="inputPassword3" className="col-sm-2 col-form-label">CGPA  </label>
                            <div className="col-sm-3">
                                <input type="text"  className="form-control"  value={fordata.CGPA}  name='CGPA'  onChange={handelinputchnage}/>
                            </div>

                            <label for="inputPassword3" className="col-sm-2 col-form-label">Year of Passing  </label>

                            <div className="col-sm-3">
                                <input type="text"  className="form-control" value={fordata.Passing_year}  name='Passing_year' onChange={handelinputchnage}/>
                            </div>
                        </div>


        </FormWizard.TabContent>


   

        <FormWizard.TabContent
          title="Provident Fund"
          icon="ti-settings"
         
          isValid={checkValidateTab()}
          validationError={errorMessages}
        >

<div className="row mb-3">
                            <label for="inputEmail3" className="col-sm-2 col-form-label">  Provident Fund Trust   
        </label>
                            <div className="col-sm-3">

                                <select  className='form-select' name='trust' onChange={handelinputchnage}>

                                <option value="RPF1">Regional PF Trust</option>
                                </select>
                                
                                  
                               
                                            </div>



                                            <label for="inputEmail3" className="col-sm-2 col-form-label">  

Provident Fund Number  
        </label>
                            <div className="col-sm-3">

                            <input type='text' className='form-control' value={fordata.fund_no}  name='fund_no' onChange={handelinputchnage}/>
                                                      </div>


                            </div>  

        <div className="row mb-3">
                            <label for="inputEmail3" className="col-sm-2 col-form-label">  Pension Trust    </label>
                            <div className="col-sm-3">

                            <select  className='form-select'  name='position_trust' onChange={handelinputchnage}>

                            
<option value="">Select</option>
<option value="RPF1">RFP1/9999/000121</option>
</select>
                              
                        
                                </div>
                            <label for="inputEmail3" className="col-sm-2 col-form-label">Type of Insitution </label>
                            <div className="col-sm-3">
                            <input type='text' className='form-control' value={fordata.type_of_inst}  name='type_of_inst' onChange={handelinputchnage}/>

                           

                                 </div>
                        </div>
                       
                        
        </FormWizard.TabContent>

        <FormWizard.TabContent
          title=" Basic Pay "
          icon="ti-check"
         
          isValid={checkValidateTab()}
          validationError={errorMessages}
        >

<div class="card card-table flex-fill"><div class="card-header"  style={{background:"#343a40", color:"white"}}><h4 class="card-title mb-0" style={{float:"left",color:"#FFF"}}>Basic Pay</h4></div><div class="card-body"><div class="table-responsive">

<button type="button" className="btn btn-warning mybtn me-4" onClick={() => addBooksHandler()}>Add Item</button>
  <table className="table custom-table table-nowrap mb-0">
                        <thead>
                          <tr>
                            <th>Pay Type </th>
                            <th>Amount </th>
                           
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody id="books-table-body">
                          <tr>
                            <td>
                            <select  className='form-select' name='basictype' onChange={handelinputchnage}> 

<option>--Basic Pay  Type--</option>
{basicpay && basicpay.map((item ,i) => (

<option value={item.id}>{item.Pay_name}</option>
    
    
    ))}
</select>   
                              
                            </td>
                         
                            <td>
                              <input
                                className="form-control"
                                min={0}
                                 defaultValue={1}
                                type="number"
                                name="Amount"
                                 />
                            </td>
                           
                            <td>
                                
                              <button
                                className="btn btn-primary"
                                >Delete</button>
                            </td>
                          </tr>
                         
                             
                             
                        </tbody>
                      </table> 
                      </div></div>
                      </div>



                    

                      <div class="card card-table flex-fill"><div class="card-header"  style={{background:"#343a40", color:"white"}}><h4 class="card-title mb-0" style={{float:"left",color:"#FFF"}}>Basic Pay Didection</h4></div><div class="card-body"><div class="table-responsive">

<button type="button" className="btn btn-warning mybtn me-4" onClick={() => adddedaction()}>Add More</button>
  <table className="table custom-table table-nowrap mb-0">
                        <thead>
                          <tr>
                            <th>Pay Type </th>
                            <th>Amount </th>
                           
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody id="didiction-table-body">
                          <tr>
                            <td>
                            <select  className='form-select' name='basictype' onChange={handelinputchnage}>

<option>--Basic Pay  Type--</option>
{basicpay && basicpay.map((item ,i) => (

<option value={item.id}>{item.Pay_name}</option>
    
    
    ))}
</select>   
                              
                            </td>
                         
                            <td>
                              <input
                                className="form-control"
                                min={0}
                                 defaultValue={1}
                                type="number"
                                name="Amount"
                                 />
                            </td>
                           
                            <td>
                                
                              <button
                                className="btn btn-primary"
                                >Delete</button>
                            </td>
                          </tr>
                         
                             
                             
                        </tbody>
                      </table> 
                      </div></div>
                      </div>
        </FormWizard.TabContent>

      
      </FormWizard>
      {/* add style */}
      <style>{`
        @import url("https://cdn.jsdelivr.net/gh/lykmapipo/themify-icons@0.1.2/css/themify-icons.css");
        .form-control {
            height: 36px;
            padding: 0.375rem 0.75rem;
            font-size: 1rem;
            font-weight: 400;
            line-height: 1.5;
            color: #495057;
            border: 1px solid #ced4da;
            border-radius: 0.25rem;
        }

      `}</style>

                   
                   
                   

                </div>
            </div>

          

        </div>

      
    </div>
</section>

    </main>
    </>
  )
}
