import React, {useEffect,useState} from 'react'
 import Headerpart from './Comonpart/Headerpart'
 import { useNavigate } from 'react-router-dom';
  import axios from 'axios';

  //import { generatePDF } from '../../helpers/PdfFunctions';

import {generatePDF}  from './helpers/PdfFunctions';
const basurl=process.env.REACT_APP_BASE_URL;

export default function Viewemployee() {
  const navigate = useNavigate();

  const [fechuserdate ,setFechuserdate]=useState('');

  

  useEffect(() => {
    if (localStorage.getItem("user")== null) {
    
      navigate('/');
    }
    const Crateid=localStorage.getItem("userAtutc");

    // `/EditCompany/${companyId}`
     axios.get(basurl+`/api/fechdatbycompnay/${Crateid}`)
     .then(res=>{
      setFechuserdate(res.data);

     })
     .catch(error=> console.log(error));
  }, []);

  //const submitHandler = async (e) => {
   const  Offerlater= async (Empid)=>{

    navigate(`/Sendofferlatter/${Empid}`)
    
  }

   const Apomentlater=async(Empid)=>{
    navigate(`/APPOINTMENTLATER/${Empid}`)

   } 
   const  HandleEdit=(id)=>{
  
    navigate(`/Edit_Employee/${id}`)
  }
  return (
    <>
    <Headerpart/>

    <main id="main" className="main">

    <section className="section">
      <div className="row">
        <div className="col-lg-12">

          <div className="card">
            <div className="card-body">
             
             
              <table className="table datatable">
                <thead>

              
                  <tr>
                    <th>
                      <b>N</b>ame
                    </th>
                    <th>Email.</th>
                    <th>Contcat Number</th>
                    <th data-type="date" data-format="YYYY/DD/MM">Employee Id</th>
                    <th>Password</th>
                    <th>Generate Offer Letter</th>
                    <th>Generate Appointment Letter </th>
                    <th>Action</th>

                  </tr>
                </thead>
                <tbody>
                {fechuserdate && fechuserdate.map((item) => (
                  <tr>
                    <td>{item.Name}</td>
                    <td>{item.email}</td>
                    <td>{item.contact_numer}</td>
                    <td>{item.emap_id}</td>
                    <td>{item.password}</td>
                    <td><a href="#" onClick={()=>Offerlater(item.id)} class="btn btn-success"> View  <i class="fa fa-eye" aria-hidden="true"></i></a></td>
                  <td><a href="#"  onClick={()=>Apomentlater(item.id)}  class="btn btn-success"> View</a></td> 
                  <td><a href="#"  onClick={()=>HandleEdit(item.id)}  class="btn btn-success">Edit</a></td> 
                  </tr>
                  ))}
                </tbody>
              </table>
             
            </div>


         </div>

        </div>
      </div>
    </section>

        </main>
    </>
  )
}
