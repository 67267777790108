import React ,{useEffect,useState}from 'react';
import { useNavigate } from 'react-router-dom';
 import axios from 'axios';

import moment from 'moment';

import Headerpart from './Comonpart/Headerpart';
const basurl=process.env.REACT_APP_BASE_URL;
export default function Viewcompnay() {
  const navigate = useNavigate(); 

  

  const [fachdatblog ,setFatchblog]=useState('');

  useEffect(() => {
    if (localStorage.getItem("user")== null) {
    
      navigate('/');
    }

    const Crateid=localStorage.getItem("Alldate");

    

     axios.get(basurl+`/api/fetchAttences/${Crateid}`)
    
    // axios.get('http://localhost:5000/api/getcompmay')
    .then(res=>{

      setFatchblog(res.data);

      
     // console.log(res.data);

    })
    .catch(error=> console.log(error));
  }, []); 


 
  return (
    <>
    <Headerpart/>
    <main id="main" className="main">

    <section className="section">
      <div className="row">
        <div className="col-lg-12">

          <div className="card">
            <div className="card-body">
             
             
              <table className="table datatable">
                <thead>
                  <tr>
                    <th>
                      <b>N</b>ame
                    </th>
                    <th>Check In date Time.</th>
                    <th>Check out date Time</th>
                    <th>Location</th>
                    <th>Action</th>
                    
                  </tr>
                </thead>
                <tbody>
                  
                {fachdatblog && fachdatblog.map((item ,i) => (
                 
                 
                  <tr>
                    <td>{i + 1}</td>
                    <td> {moment(item.check_in).format('MMMM Do YYYY, h:mm:ss a')}   {moment(item.check_in).format('M/D/YYYY')}
                        
                       </td>
                    <td>  {moment(item.check_out).format('MMMM Do YYYY, h:mm:ss a')}</td>

                    <td> <iframe id="iframeId"  src={`https://maps.google.com/maps?q=${item.latitude},${item.longitude} &hl=es;&output=embed`} height="100px" width="100%"></iframe></td>
                    <td>{item.Status=="0"?
                    <span style={{background:"red", color:"#fff",padding:"5px"}}>Abase</span>
                    
                    :
                    <span style={{background:"green", color:"#fff",padding:"5px"}}>Present </span>
                }


                { }
                    </td>
                    
                   
                  </tr>
                  ))}
                </tbody>
              </table>
             
            </div>
          </div>

        </div>
      </div>
    </section>

        </main>
    </>
    
  )
}
