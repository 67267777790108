import React ,{useEffect,useState}from 'react';
import { useNavigate } from 'react-router-dom';
 import axios from 'axios';

import moment from 'moment';


 import Headerpart from '../Comonpart/Headerpart';
 const basurl=process.env.REACT_APP_BASE_URL;
export default function Stateslist() {
  const navigate = useNavigate(); 

  

  const [fachdatblog ,setFatchblog]=useState('');
   const [State_name,setName]=useState('');
   const [CountryId,setCountryId]=useState('');
  // const [countries, setCountries] = useState([]);

   const [counterylist ,setCounterylist]=useState('');

  //  useEffect(() => {
  //   // Fetch countries from the backend API
  //   axios.get('http://localhost:5000/api/getAllCountryss')
  //     .then(response => setCountries(response.data))
  //     .catch(error => console.error('Error fetching countries:', error));
  // }, []);
   const Crateid=localStorage.getItem("userAtutc");
    function fetchempltype()
    {
        axios.get(basurl+`/api/getAllStates/${Crateid}`)
        .then(res=>{
            setFatchblog(res.data.roles);
            setFatchblog(res.data);
            console.log(res.data);
    
        })
        .catch(error=> console.log(error));

    }

    function fetchcountery()
    {
        axios.get(`/api/getAllCountrys/${Crateid}`)
        .then(res=>{
          setCounterylist(res.data);
           
            console.log(res.data);
    
        })
        .catch(error=> console.log(error));

    }

  useEffect(() => {
    if (localStorage.getItem("user")== null) {
    
      navigate('/');
    }

    fetchempltype();
    fetchcountery();

    

  }, []); 


  const handelsubmit=()=>{ 
    const create_id=localStorage.getItem("userAtutc");
    axios.post(basurl+'/api/createState',{State_name,CountryId,create_id})
     .then(res=>{

         console.log(res.data.success);
      
         if(res.data.success== true)
         {
             alert("Succes full Add the data"); 
             fetchempltype();
            
         }

         else
         {

             alert("Soming Mesiing ")
         }

     })
     .catch(err=>console.log(err));



}


const  HandleEdit=(id)=>{
  navigate(`/Dashbord/Edit_state/${id}`)
}
  return (
    <>
    <Headerpart/>
    <main id="main" className="main">

    <section className="section">
      <div className="row">
        <div className="col-lg-12">

          <div className="card">
            <div className="card-body">


            <h5 className="card-title">Add States  List  </h5>

                   
                   
<div className="row mb-3">

<label for="inputEmail3" className="col-sm-2 col-form-label"> Select Country </label>
<div className="col-sm-3">
    <select onChange={e=>setCountryId(e.target.value)} className='form-select'>

        <option>--Select Countery--</option>
        {fachdatblog && fachdatblog.map((item ) => (

            <option value={item.id}>{item.name}</option>
        ))}

    </select>
      
    </div>


    <label for="inputEmail3" className="col-sm-2 col-form-label">States  Name</label>
    <div className="col-sm-3">
        <input type="text" className="form-control" id="inputText" onChange={e=>setName(e.target.value)}/>
    </div>


    <div className="text-center">

<button type="submit" onClick={handelsubmit} className="btn btn-primary">Submit</button>
   
</div>
</div>
             
             
              <table className="table datatable">
                <thead>
                  <tr>
                    <th>
                      S.N
                    </th>
                    <th>Country   Name : </th>

                    <th>States Name : </th>
                 
                    <th>Action</th>
                    
                  </tr>
                </thead>
                <tbody>
                  
                {fachdatblog && fachdatblog.map((item ,i) => (
                 
                 
                  <tr>
                    <td>{i + 1}</td>
                    <td> {item.name}
                        
                       </td>

                       <td> {item.State_name}
                        
                        </td>
                 
                    <td>
                      {/* {item.Status=="0"?
                    <span style={{background:"red", color:"#fff",padding:"5px"}}>Abase</span>
                    
                    :
                    <span style={{background:"green", color:"#fff",padding:"5px"}}>Present </span>
                } */}

<button type="button" class="btn btn-default btn-sm"   onClick={()=>HandleEdit(item.id)}>
          <span class="glyphicon glyphicon-edit"></span> Edit  
        </button>
                { }
                    </td>
                    
                   
                  </tr>
                  ))}
                </tbody>
              </table>
             
            </div>
          </div>

        </div>
      </div>
    </section>

        </main>
    </>
    
  )
}
