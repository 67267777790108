import React, {useEffect,useState} from 'react'
 import Headerpart from './Comonpart/Headerpart';
 import { useNavigate } from 'react-router-dom';
 import axios from 'axios';
  import moment from 'moment';
  const basurl=process.env.REACT_APP_BASE_URL;

export default function Applaylive() {
    const navigate = useNavigate();

    const [name,setName]=useState('');
     const [todate,setTodate]=useState('');
     const [fromdate,setFromdate]=useState('');

     const[alivedat, setFatchblog]=useState('');

      const[leavetypes,setpLeavetypes]=useState('');

     const Crateid=localStorage.getItem("userAtutc");

     const rolsels= localStorage.getItem("Userrole");
    

  useEffect(() => {
   
    if (localStorage.getItem("user")== null) {
     
      navigate('/');
    }

   

     axios.get(basurl+`/api/Fetchlivedate/${Crateid}/${rolsels}`)
    .then(res=>{

      setFatchblog(res.data);
    

    })
    Fetchleavestype();
  }, []);

  const [fetchleavedata,setFetchleavedata]=useState('');
   function Fetchleavestype()
   {

    axios.get(basurl+`/api/leavedatacompany/${Crateid}/${rolsels}`)
       .then(res=>{
          
        setFetchleavedata(res.data); 
   
       })
       .catch(error=> console.log(error));
   }

  const handelsubmit=()=>{
      
        const Crateid=localStorage.getItem("userAtutc");
       
        axios.post(basurl+'/api/Applaylive',{name,todate,fromdate,Crateid,leavetypes})
   
          
         .then(res=>{
          
             if(res.data>0)
             {
                 alert("Succes full Applay Live"); 
                
     
             }

             else
             {

                 alert("Soming Mesiing ")
             }

         })
         .catch(err=>console.log(err));



    }

     const changstatus=(id)=>
     {
        

         if(rolsels=='Com')
         {

            axios.get(basurl+`/api/Updateliveststus/${Crateid}/${id}`)
            .then(res=>{
        
        
            })
      
         }
          else
          {

             alert("Not authorized  To peform this Action plese contcat yor compnay Admin");
          }

     }
  return (
    <>
    <Headerpart/>
    <main id="main" className="main">

<section className="section">
    <div className="row">
        <div className="col-lg-12">

        {rolsels=="EMP"&&
  <>
  

            <div className="card">
                <div className="card-body">
                    <h5 className="card-title">New Live Applay </h5>

                   
                        <div className="row mb-3">

                        <label for="inputEmail3" className="col-sm-2 col-form-label">Select Leave Types  </label>
                            <div className="col-sm-3">
                                <select className='form-select' id="Leaveid"   onChange={e=>setpLeavetypes(e.target.value)}>
                                  
                                  <option>--Select --</option>
                                  {fetchleavedata && fetchleavedata.map((item ,i) => (
                                    
                                    <option value={item.id}>{item.live_tpye}  | Total Leave:{item.Total_day} | Use Leave :{item.count_use_live}</option>
                                    ))}
                                </select>
                            </div>
                            <label for="inputEmail3" className="col-sm-2 col-form-label">Resion To apllay Live  </label>
                            <div className="col-sm-3">
                                <input type="text" className="form-control" id="inputText" onChange={e=>setName(e.target.value)}/>
                            </div>
                        </div>
                        <div className="row mb-3">
                            <label for="inputEmail3" className="col-sm-2 col-form-label"> To date</label>
                            <div className="col-sm-3">
                                <input type="date" className="form-control" id="inputEmail" onChange={e=>setTodate(e.target.value)}/>
                            </div>
                            <label for="inputPassword3" className="col-sm-2 col-form-label">From Date </label>
                             
                            <div className="col-sm-3">
                                <input type="date"  className="form-control" id="inputPassword" onChange={e=>setFromdate(e.target.value)}/>
                            </div>
                        </div>
                  
                        <div className="text-center">

                        <button type="submit" onClick={handelsubmit} className="btn btn-primary">Submit</button>
                           
                        </div>
                   
                </div>
            </div>
          
  </>
        }
            <table className="table datatable">
                <thead>
                  <tr>
                    <th></th>
                    <th>
                    Apply  To
                    </th>
                    <th>Apply From </th>

                    <th>Total Number of days  </th>
                    <th>Status</th>

                    <th>Desc</th>
                   
                    
                  </tr>
                </thead>
                <tbody>
                  
                {alivedat && alivedat.map((item ,i) => (
                 
                 
                  <tr>
                    <td>{i + 1}</td>
                    <td> {moment(item.todate).format('MMMM Do YYYY')}   
                        
                       </td>
                    <td>  {moment(item.fromdate).format('MMMM Do YYYY')}</td>
                    <td>  {item.totalday} Day</td>
                    {rolsels=="EMP"&&
  <>
                    <td>{item.status=="0"?
                  
                      <a href="#" onClick={changstatus.bind(this, item.id)}>
                    <span    style={{background:"red", color:"#fff",padding:"5px"}} >Pending    </span>
                    </a>
                    
                    :
                    <span style={{background:"green", color:"#fff",padding:"5px"}}>Aproved  By  {item.Name}</span>
                }


                { }
                    </td>
                    </>

                    
}

{rolsels=="Com"&&
  <>
                    <td>{item.status=="0"?
                  
                      <a href="#" onClick={changstatus.bind(this, item.id)}>
                    <span    style={{background:"red", color:"#fff",padding:"5px"}} >Pending  Applay By  {item.Name} </span>
                    </a>
                    
                    :
                    <span style={{background:"green", color:"#fff",padding:"5px"}}>Aproved    </span>
                }


                { }
                    </td>
                    </>

                    
}



                    
                    <td>  {item.livedesc}</td>
                  </tr>
                  ))}
                </tbody>
              </table>

        </div>

      
    </div>
</section>

    </main>
    </>
  )
}
