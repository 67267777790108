import React, {useEffect,useState} from 'react'
 import Headerpart from './Comonpart/Headerpart';
 import { useNavigate } from 'react-router-dom';
 import axios from 'axios';
 import { useParams } from 'react-router-dom';
 const basurl=process.env.REACT_APP_BASE_URL;

export default function Addnewcompnay() {
    const navigate = useNavigate();

    const [name,setName]=useState('');
     const [email,setEmail]=useState('');
     const [conatctnumabe,setConatctnumabe]=useState('');
     const [address,setAddress]=useState('');
     const [pencard,setPen]=useState('');
     const [adrdhrcard,setAdrdhrcard]=useState('');
     const companyId=useParams()
    //console.log("id",companyId.companyId)
    const [editedate ,setEditedate]=useState('');
     

  useEffect(() => {
    //const loggedInUser = localStorage.getItem("user");
    if (localStorage.getItem("user")== null) {
      //console.log(loggedInUser);
      navigate('/');
    }
        axios.get(basurl+`/api/getbyid/${companyId.companyId}`)
        .then(res=>{
            setEditedate(res.data);
            console.log(res.data);

        //   console.log(editedate[0].Name);
        })
    // let eserid=Params();
    // console.warn(eserid);
  }, []);

  const handelsubmit=()=>{
         const formdata= new FormData();
        // formdata.append('Name',name)
        // formdata.append('Address',address)
        // formdata.append('Adhar_card',adrdhrcard)
        // formdata.append('pen_card',pencard)
        // formdata.append('contact_numer',conatctnumabe)
        // formdata.append('email',email)
         axios.post(basurl+`/api/USerdateupdate/${companyId.companyId}`,{name,address,adrdhrcard,pencard,conatctnumabe,email})
          
         .then(res=>{
          
             if(res.data>0)
             {
                 alert("Succes full Add the data"); 
                
             }

             else
             {

                 alert("Soming Mesiing ")
             }

         })
         .catch(err=>console.log(err));



    }
  return (
    <>
    <Headerpart/>
    <main id="main" className="main">

<section className="section">
    <div className="row">
        <div className="col-lg-12">

            <div className="card">
                <div className="card-body">
                    <h5 className="card-title">New Company  Registion </h5>

                   
                   
                        <div className="row mb-3">
                            <label for="inputEmail3" className="col-sm-2 col-form-label">Company  Name</label>
                            <div className="col-sm-10">
                                <input type="text" className="form-control" id="inputText" value={editedate[0]?.Name} onChange={e=>setName(e.target.value)} />
                            </div>
                        </div>
                        <div className="row mb-3">
                            <label for="inputEmail3" className="col-sm-2 col-form-label"> Company Email</label>
                            <div className="col-sm-10">
                                <input type="email" className="form-control" id="inputEmail" value={editedate[0]?.email} onChange={e=>setEmail(e.target.value)}/>
                            </div>
                        </div>
                        <div className="row mb-3">
                            <label for="inputPassword3" className="col-sm-2 col-form-label">Contact Number</label>
                            <div className="col-sm-10">
                                <input type="number"  className="form-control" id="inputPassword" value={editedate[0]?.contact_numer} onChange={e=>setConatctnumabe(e.target.value)}/>
                            </div>
                        </div>

                        <div className="row mb-3">
                            <label for="inputPassword3" className="col-sm-2 col-form-label">Address </label>
                            <div className="col-sm-10">
                                <input type="text"  className="form-control" id="inputPassword"   value={editedate[0]?.Address} onChange={e=>setAddress(e.target.value)}/>
                            </div>
                        </div>


                        <div className="row mb-3">
                            <label for="inputPassword3" className="col-sm-2 col-form-label">Pen Card </label>
                            <div className="col-sm-10">
                                <input type="text"  className="form-control" id="inputPassword" value={editedate[0]?.pen_card}  onChange={e=>setPen(e.target.value)} />
                            </div>
                        </div>



                        <div className="row mb-3">
                            <label for="inputPassword3" className="col-sm-2 col-form-label">Adhar Card </label>
                            <div className="col-sm-10">
                                <input type="text"  className="form-control" id="inputPassword" value={editedate[0]?.Adhar_card} onChange={e=>setAdrdhrcard(e.target.value)}/>
                            </div>
                        </div>

                        <div className="text-center">

                        <button type="submit" onClick={handelsubmit} className="btn btn-primary">Submit</button>
                           
                        </div>
                   
                </div>
            </div>

          

        </div>

      
    </div>
</section>

    </main>
    </>
  )
}
