import React ,{useEffect,useState}from 'react';
import { useNavigate } from 'react-router-dom';
 import axios from 'axios';

import moment from 'moment';


 import Headerpart from '../Comonpart/Headerpart';
 const basurl=process.env.REACT_APP_BASE_URL;
export default function Add_live_type() {
  const navigate = useNavigate(); 

  
   const [City_name,setName]=useState('');
   const [StateId,setCountryId]=useState('');


   const [Citylist ,setCitylist]=useState('');

   const Crateid=localStorage.getItem("userAtutc");

    function Fetchcitylist()
    {
        axios.get(basurl+`/api/fetch_livedata/${Crateid}`)
        .then(res=>{
            //setFatchblog(res.data.roles);
            setCitylist(res.data);
           
    
        })
        .catch(error=> console.log(error));

    }

  useEffect(() => {
    if (localStorage.getItem("user")== null) {
    
      navigate('/');
    }

    Fetchcitylist();

    

  }, []); 


  const handelsubmit=()=>{ 
    const create_id=localStorage.getItem("userAtutc");
    axios.post(basurl+'/api/Addlivetype',{City_name,create_id})
     .then(res=>{

         console.log(res.data.success);
      
         if(res.data>0)
         {
             alert("Succes full Add the data"); 
            // fetchempltype();
             Fetchcitylist();
            
         }

         else
         {

             alert("Soming Mesiing ")
         }

     })
     .catch(err=>console.log(err));



}
  return (
    <>
    <Headerpart/>
    <main id="main" className="main">

    <section className="section">
      <div className="row">
        <div className="col-lg-12">

          <div className="card">
            <div className="card-body">


            <h5 className="card-title">Add  Leave  Management  </h5>

                   
                   
<div className="row mb-3">


    <label for="inputEmail3" className="col-sm-2 col-form-label">Leave  Name</label>
    <div className="col-sm-3">
        <input type="text" className="form-control" id="inputText" onChange={e=>setName(e.target.value)}/>
    </div>


    <div className="text-center">

<button type="submit" onClick={handelsubmit} className="btn btn-primary">Submit</button>
   
</div>
</div>
             
             
              <table className="table datatable">
                <thead>
                  <tr>
                    <th>
                      S.N
                    </th>
                    <th>Name   Name : </th>

                  
                 
                    <th>Action</th>
                    
                  </tr>
                </thead>
                <tbody>
                  
                {Citylist && Citylist.map((item ,i) => (
                 
                 
                  <tr>
                    <td>{i + 1}</td>
                    <td> {item.live_tpye}
                        
                       </td>

                      
                 
                    <td>{item.Status=="0"?
                    <span style={{background:"red", color:"#fff",padding:"5px"}}>Abase</span>
                    
                    :
                    <span style={{background:"green", color:"#fff",padding:"5px"}}>Present </span>
                }


                { }
                    </td>
                    
                   
                  </tr>
                  ))}
                </tbody>
              </table>
             
            </div>
          </div>

        </div>
      </div>
    </section>

        </main>
    </>
    
  )
}
