import React ,{useEffect,useState}from 'react';
import { useNavigate } from 'react-router-dom';
 import axios from 'axios';

import moment from 'moment';
import $, { data } from "jquery";


 import Headerpart from '../Comonpart/Headerpart';
 const basurl=process.env.REACT_APP_BASE_URL;
export default function Add_leave_quta() {
  const navigate = useNavigate(); 

  
   const [City_name,setName]=useState('');
   const [StateId,setCountryId]=useState('');


   const [Citylist ,setCitylist]=useState('');

   const [leavetype ,setLeavetype]=useState('');

   const Crateid=localStorage.getItem("userAtutc");

    const rolse="";

    const [fetchleavedata,setFetchleavedata]=useState('');
    const rolsels= localStorage.getItem("Userrole");
   function Leavedatafetch()
   {
       axios.get(basurl+`/api/leavedatacompany/${Crateid}/${rolsels}`)
       .then(res=>{
          
        setFetchleavedata(res.data);

          
          
   
       })
       .catch(error=> console.log(error));

   }

    function Fetchcitylist()
    {
        axios.get(basurl+`/api/fetch_livedata/${Crateid}`)
        .then(res=>{
           
            setCitylist(res.data);

            setLeavetype(res.data);
           
    
        })
        .catch(error=> console.log(error));

    }

  useEffect(() => {
    if (localStorage.getItem("user")== null) {
    
      navigate('/');
    }

    Fetchcitylist();
    Leavedatafetch();

    

  }, []); 


  const handelsubmit=()=>{ 
    const create_id=localStorage.getItem("userAtutc");

    let books_items = [];
   // for add books item
   let books_total_items = $("#books-table-body tr").length
   for (let i = 0; i < books_total_items; i++) {
     let books_item = {}
     $(`#books-table-body tr:nth-child(${i + 1}) :input`).each(function () {
       if (this.value) {
         books_item[this.name] = this.value;
       }
     });
     if (Object.keys(books_item).length > 0) {
       books_items.push(books_item)
     }
   }
    axios.post(basurl+'/api/Add_leavetype',{books_items,create_id})
     .then(res=>{

         console.log(res.status);
      
         if(res.status==200)
         {
             alert("Succes full Add the data"); 
            // fetchempltype();
             //Fetchcitylist();
             Leavedatafetch();
            
         }

         else
         {

             alert("Soming Mesiing ")
         }

     })
     .catch(err=>console.log(err));



}


const addBooksHandler = () => {

    let items2 = leavetype?.map((item, i) => {
          return (
            `<option key=${i} value=${item?.id}>${item?.live_tpye}</option>`
         )
         })
    
    $("tbody#books-table-body").append(`<tr>
    
      <td>
      <select class="form-select company-select-option" name="basictype">
      <option value=''>Select</option>
      ${items2}
      </select>
      </td>
      <td>
        <input
          class="form-control"
          min="0"
          step=".01"
          type="number"
          name="Amount"
          max='100' />
      </td>
    <td>
    <button class="btn btn-primary">Delete</button>
    </td>
    </tr>`)
   
    $("tbody#books-table-body tr td:last-child button").on("click", function (e) {
        deleteItemHandler(e)
      });
   
  }

  const deleteItemHandler = (e) => {
    let tr = e.target.parentElement.parentElement
    tr.remove()
  }

  return (
    <>
    <Headerpart/>
    <main id="main" className="main">

    <section className="section">
      <div className="row">
        <div className="col-lg-12">

          <div className="card">
            <div className="card-body">

                   
                   
<div className="row mb-3">


<div class="card card-table flex-fill"><div class="card-header"  style={{background:"#343a40", color:"white"}}><h4 class="card-title mb-0" style={{float:"left",color:"#FFF"}}>Add Leave quota</h4></div><div class="card-body"><div class="table-responsive">

<button type="button" className="btn btn-warning mybtn me-4" onClick={() => addBooksHandler()}>Add Item</button>
  <table className="table custom-table table-nowrap mb-0">
                        <thead>
                          <tr>
                            <th>Types Leave </th>
                            <th>Total Nuber of Day </th>
                           
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody id="books-table-body">
                          <tr>
                            <td>
                            <select  className='form-select' name='basictype'>

<option>--Types of Leave--</option>
{leavetype && leavetype.map((item ,i) => (

<option value={item.id}>{item.live_tpye}</option>
    
    
    ))}
</select>   
                              
                            </td>
                         
                            <td>
                              <input
                                className="form-control"
                                min={0}
                                 defaultValue={1}
                                type="number"
                                name="Amount"
                                 />
                            </td>
                           
                            <td>
                                
                              <button
                                className="btn btn-primary"
                                >Delete</button>
                            </td>
                          </tr>
                         
                             
                             
                        </tbody>
                      </table> 
                      </div></div>
                      </div>



    <div className="text-center">

<button type="submit" onClick={handelsubmit} className="btn btn-primary">Submit</button>
   
</div>
</div>
             
             
              <table className="table datatable">
                <thead>
                  <tr>
                    <th>
                      S.N
                    </th>
                    <th>Types Leave </th>
                    <th>Total Nuber of Day </th>

                  
                 
                    <th>Action</th>
                    
                  </tr>
                </thead>
                <tbody>
                  
                {fetchleavedata && fetchleavedata.map((item ,i) => (
                 
                 
                  <tr>
                    <td>{i + 1}</td>
                    <td> {item.live_tpye}
                        
                       </td>

                       <td> {item.Total_day}
                        
                        </td>

                      
                 
                    <td>{item.Status=="0"?
                    <span style={{background:"red", color:"#fff",padding:"5px"}}>Abase</span>
                    
                    :
                    <span style={{background:"green", color:"#fff",padding:"5px"}}>Present </span>
                }


                { }
                    </td>
                    
                   
                  </tr>
                  ))}
                </tbody>
              </table>
             
            </div>
          </div>

        </div>
      </div>
    </section>

        </main>
    </>
    
  )
}
