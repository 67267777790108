import html2pdf from 'html2pdf.js';

export function generateAndSavePDF(element, filename) {
    const options = {
        filename,
        margin: [20, 20, 20, 20],
        image: { type: 'jpeg', quality: 0.98 },
        html2canvas: { dpi: 192, letterRendering: true },
        jsPDF: { unit: 'pt', format: 'a4', orientation: 'portrait' }
    };
    html2pdf().set(options).from(element).save();
}

export function generatePDF(element) {
    const options = {
        margin: [20, 20, 20, 20],
        image: { type: 'jpeg', quality: 0.98 },
        html2canvas: { dpi: 192, letterRendering: true },
        jsPDF: { unit: 'pt', format: 'a4', orientation: 'portrait' }
    };
    return html2pdf().set(options).from(element).output('blob')
}

export async function generateBarcodePDF(element, filename) {
    const options = {
        filename,
        margin: [0, 0, 0, 0],
        image: { type: 'pdf', quality: 1 },
        html2canvas: { dpi: 192, letterRendering: true },
        jsPDF: { unit: 'pt', format: [71, 284], orientation: 'landscape' }
    };
    return html2pdf().set(options).from(element).save();
}
