
import React, { useState,useEffect } from 'react'
 import { Link } from 'react-router-dom';
 import { useNavigate } from 'react-router-dom';
  import axios from 'axios';
 
  const basurl=process.env.REACT_APP_BASE_URL;

export default function Headerpart() {
  const navigate = useNavigate();

  
  const [lat, setLet]=useState();

   const [log, setLog]=useState();
   
   useEffect(() => {
  
    navigator.geolocation.getCurrentPosition((posistio)=>{

      setLet(posistio.coords.latitude);
      setLog (posistio.coords.longitude);
     
    })
    Attenloge();
  }, []);


   

  const handleLogout = () => {

    const Crateid=localStorage.getItem("Alldate");

    axios.get(basurl+`/api/Updateattende/${Crateid}/${lat}/${log}`)
    
    // axios.get('http://localhost:5000/api/getcompmay')
    .then(res=>{

     
      console.log(res.data);

    })
      
    localStorage.clear();
    sessionStorage.clear();
    navigate("/")
  };


   function Attenloge()
   {

    const Crateid=localStorage.getItem("Alldate");
      let lat='23333';
      let logitiyur='54444';

    axios.post(basurl+'/api/Attenloge',{Crateid,lat,logitiyur}) 
         .then(res=>{
          
             if(res.data>0)
             {
                //  alert("Succes full Add Comment"); 
                 
                
             }

             else
             {

                 alert("Soming Mesiing ")
             }

         })
         .catch(err=>console.log(err));



     console.log(lat);

    //axios.post(basurl+`/api/Attenloge/`,{Crateid,lat,log})
    
   }



  const Userrila=localStorage.getItem("Userrole");
 
  return (
   <>
   <header id="header" className="header fixed-top d-flex align-items-center">

<div className="d-flex align-items-center justify-content-between">
<Link to="/Dashbord"  className="logo d-flex align-items-center" ><img src="/assets/img/logo.png" alt=""/>
    <span className="d-none d-lg-block">{localStorage.getItem("companyname")}</span></Link>
 
  
  <i className="bi bi-list toggle-sidebar-btn"></i>
</div>



<nav className="header-nav ms-auto">
  <ul className="d-flex align-items-center">

    

  

    <li className="nav-item dropdown pe-3">

      <a className="nav-link nav-profile d-flex align-items-center pe-0" href="#" data-bs-toggle="dropdown">
        <img src="/assets/img/profile-img.jpg" alt="Profile" className="rounded-circle"/>
        <span className="d-none d-md-block dropdown-toggle ps-2">{localStorage.getItem("user")}</span>
      </a>

      <ul className="dropdown-menu dropdown-menu-end dropdown-menu-arrow profile">
        <li className="dropdown-header">
          <h6>Kevin Anderson</h6>
          <span>Web Designer</span>
        </li>
        <li>
          <hr className="dropdown-divider"/>
        </li>

        <li>
          <a className="dropdown-item d-flex align-items-center" href="#">
            <i className="bi bi-person"></i>
            <span>My Profile</span>
          </a>
        </li>
        <li>
          <hr className="dropdown-divider"/>
        </li>

        <li>
          <a className="dropdown-item d-flex align-items-center" href="#">
            <i className="bi bi-gear"></i>
            <span>Account Settings</span>
          </a>
        </li>
        <li>
          <hr className="dropdown-divider"/>
        </li>

        <li>
          <a className="dropdown-item d-flex align-items-center" href="#">
            <i className="bi bi-question-circle"></i>
            <span>Need Help?</span>
          </a>
        </li>
        <li>
          <hr className="dropdown-divider"/>
        </li>

        <li>
          <a className="dropdown-item d-flex align-items-center" href="#">
            <i className="bi bi-box-arrow-right"></i>
  
            <button onClick={handleLogout} style={{border:"none",background:"none"}}>logout</button>
              
          </a>
        </li>

      </ul>
    </li>

  </ul>
</nav>

</header>



<aside id="sidebar" className="sidebar">

  {localStorage.getItem("user")?
  <>

<ul className="sidebar-nav" id="sidebar-nav">

  <li className="nav-item">
  <Link to="/Dashbord" className="nav-link "   >
   
      <i className="bi bi-grid"></i>
      <span>Dashboard</span>
   
    </Link>
  </li>
{Userrila=="ADMIN"&&
  <>
  <li className="nav-item">
    <a className="nav-link collapsed" data-bs-target="#forms-nav" data-bs-toggle="collapse" href="#">
      <i className="bi bi-journal-text"></i><span>Manage Compay</span><i className="bi bi-chevron-down ms-auto"></i>
    </a>
    <ul id="forms-nav" className="nav-content collapse " data-bs-parent="#sidebar-nav">
      <li>

      <Link to="/Dashbord/AddCompay"   >Add New Company</Link>
       
       
      </li>

      <li>

<Link to="/Dashbord/Viewcompeny"  >View Company</Link>
 
 
</li>
      
    </ul>
  </li>

  
  <li className="nav-item">
    <a className="nav-link collapsed" data-bs-target="#forms-navlive" data-bs-toggle="collapse" href="#">
      <i className="bi bi-journal-text"></i><span>Manage Levels   </span><i className="bi bi-chevron-down ms-auto"></i>
    </a>
    <ul id="forms-navlive" className="nav-content collapse " data-bs-parent="#sidebar-nav">
      <li>

      <Link to="/Dashbord/yearlyholiday"   >Add Holiday Levels</Link>
       
       
      </li>

      <li>

<Link to="/Dashbord/AddCompay"   >Add employee  Levels</Link>
 
 
</li>

      <li>

<Link to="/Dashbord/Viewcompeny"  >View Company</Link>
 
 
</li>
      
    </ul>
  </li>

  </>
}



{Userrila=="Com"&&
<>


<li className="nav-item">
    <a className="nav-link collapsed" data-bs-target="#forms-navmaster" data-bs-toggle="collapse" href="#">
      <i className="bi bi-journal-text"></i><span>Manage  Master Data </span><i className="bi bi-chevron-down ms-auto"></i>
    </a>
    <ul id="forms-navmaster" className="nav-content collapse " data-bs-parent="#sidebar-nav">
      <li>

      <Link to="/Dashbord/Add_employee_type"  >Add  Employee  Type</Link>
       
       
      </li>

      <li>

<Link to="/Dashbord/Add_roles"    >Add Rols </Link>


<li>

<Link to="/Dashbord/Project_seting"  >Add Project</Link>
 
 
</li>

<li>

<Link to="/Dashbord/Add_countery"  >Add Country</Link>
 
 
</li>
<li>

<Link to="/Dashbord/Add_States"  >Add States </Link>
 
 
</li>

<li>

<Link to="/Dashbord/Add_City"  >Add City </Link>
 
 
</li>

<li>

<Link to="/Dashbord/Add_livetype"  >Add  Leave Type</Link>
 
 
</li>


<li>

<Link to="/Dashbord/Leave_quata"  >Add  Leave Quota</Link>
 
 
</li>




 
</li>
      
    </ul>
  </li>
  


  <li className="nav-item">
    <a className="nav-link collapsed" data-bs-target="#forms-nav" data-bs-toggle="collapse" href="#">
      <i className="bi bi-journal-text"></i><span>Manage  Employee </span><i className="bi bi-chevron-down ms-auto"></i>
    </a>
    <ul id="forms-nav" className="nav-content collapse " data-bs-parent="#sidebar-nav">
      <li>

      <Link to="/Dashbord/AddEmployee"  >Add New Employee</Link>
       
       
      </li>

      <li>

<Link to="/Dashbord/Viewemployee"    >View Employee</Link>


<li>

<Link to="/Dashbord/yearlyholiday"   >Add Holiday </Link>
 
 
</li>
<li>

<Link to="/Dashbord/applaylive"   > Applay Leave Details  </Link>
 
 
</li>
 
</li>
      
    </ul>
  </li>
  
  


  <li className="nav-item">
    <a className="nav-link collapsed" data-bs-target="#forms-navtimeshet" data-bs-toggle="collapse" href="#">
      <i className="bi bi-journal-text"></i><span>Manage  Time Sheet </span><i className="bi bi-chevron-down ms-auto"></i>
    </a>
    <ul id="forms-navtimeshet" className="nav-content collapse " data-bs-parent="#sidebar-nav">
    

      <li>

<Link to="/Dashbord/Add_new_timesheet"    >Add New Time Sheet</Link>


<li>

<Link to="/Dashbord/Viewstimesheet"   >View Timesheet  </Link>
 
 
</li>


<li>
<Link to="/Dashboard/Approvalcomments">Approval Comments</Link>
</li>

 
</li>
      
    </ul>
  </li>
  
  </>






}

{Userrila=="EMP"&&
<>
  <li className="nav-item">
    <a className="nav-link collapsed" data-bs-target="#forms-nav" data-bs-toggle="collapse" href="#">
      <i className="bi bi-journal-text"></i><span>Manage   Attendance </span><i className="bi bi-chevron-down ms-auto"></i>
    </a>
    <ul id="forms-nav" className="nav-content collapse " data-bs-parent="#sidebar-nav">
    
      <li>

<Link to="/Dashbord/ViewAttendance"    >View Attendance</Link>
 
 
</li>
<li>

<Link to="/Dashbord/Holidaylist"   > Holiday List</Link>
 
 
</li>

<li>

<Link to="/Dashbord/applaylive"   > Applay Live</Link>
 
 
</li>



      
    </ul>
  </li>

  <li className="nav-item">
    <a className="nav-link collapsed" data-bs-target="#forms-navtimeshet" data-bs-toggle="collapse" href="#">
      <i className="bi bi-journal-text"></i><span>Manage  Time Sheet </span><i className="bi bi-chevron-down ms-auto"></i>
    </a>
    <ul id="forms-navtimeshet" className="nav-content collapse " data-bs-parent="#sidebar-nav">

      <li>

<Link to="/Dashbord/Add_new_timesheet"    >Add New Time Sheet</Link>


<li>

<Link to="/Dashbord/Viewstimesheet"   >View Timesheet  </Link>
 
 
</li>
<li>
<Link to="/Dashboard/Approvalcomments">Approval Comments</Link>
</li>
</li>
      
    </ul>
  </li>
  
  </>

}


  

 

</ul>
</>
:
<>
<ul className="sidebar-nav" id="sidebar-nav">

 
 

 <li>

<Link to="/"    >Login</Link>
 
 
</li>
      
    
  

</ul>
</>
}
</aside>

   </>
  )
}
