import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import reportWebVitals from './reportWebVitals';
 import Login from './Component/Login';
  import Dashbord from './Component/Dashbord';
   import Addnewcompnay from './Component/Addnewcompnay';
    import Viewcompnay from './Component/Viewcompnay';

     import Addemployee from './Component/Addemployee';

      import Viewemployee from './Component/Viewemployee';
      import EditCompany from "./Component/EditCompany"  
       import Attendance from './Component/Attendance'
        import Holidaylive from './Component/Holidaylive';
         import Holidaylist from './Component/Holidaylist';
          import Applaylive from './Component/Applaylive'

          import Projectseting from './Component/Projectseting/Projectseting';
           import Addnewtimesheet from './Component/Timesheet/Addnewtimesheet';
            import Viewtimesheet from './Component/Timesheet/Viewtimesheet';
             import Addcomment from './Component/Timesheet/Addcomment';
              import Addemplayess  from './Component/Emplayeemaster/Addemplayess';

               import Addemployeetype from './Component/Commoncomponent/Addemployeetype';
                import Addroles from './Component/Commoncomponent/Addroles';
                 import Addprojects from './Component/Commoncomponent/Addprojects';
                  import Addcountery  from './Component/Commoncomponent/Addcountery';
                   import Stateslist from './Component/Commoncomponent/Stateslist';
                    import Addcity from './Component/Commoncomponent/Addcity';
                     import Employeetype from './Component/Commoncomponent/Employeetype';
                      import Add_live_type  from'./Component/Commoncomponent/Add_live_type';

                       import  Add_leave_quta from './Component/Commoncomponent/Add_leave_quta';

                       //EditCompany
                import Sendofferlatter  from  './Component/Sendofferlatter';
                 import APPOINTMENTLATER  from './Component/APPOINTMENTLATER';
                  import Editcountery from './Component/Commoncomponent/Editcountery';
                   import Editstate from './Component/Commoncomponent/Editstate';
                    import Editcity from './Component/Commoncomponent/Editcity';
                    import EditEmployeeType from './Component/Commoncomponent/EditEmployeeType';
                    import Approvalcomments from './Component/Timesheet/Approvalcomments';
  
    
export default function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route index element={<Login />} />
        <Route path="/Dashbord" element={<Dashbord />} />
        <Route path="/Dashbord/AddCompay" element={<Addnewcompnay />} />
        <Route path="/Dashbord/Viewcompeny" element={<Viewcompnay />} />
      
        <Route path="/Dashbord/AddEmployee" element={<Addemployee />} />
        <Route path="/Dashbord/Viewemployee" element={<Viewemployee />} />
        
        <Route path="/EditCompany/:companyId" element={<EditCompany />} />
         <Route path="/Dashbord/ViewAttendance/" element={<Attendance />} />
         <Route path="/Dashbord/yearlyholiday/" element={<Holidaylive />} />
       
         <Route path="/Dashbord/Holidaylist/" element={<Holidaylist />} />
        
         <Route path="/Dashbord/applaylive/" element={<Applaylive />} />
         <Route path="/Dashbord/Project_seting/" element={<Projectseting />} />
         <Route path="/Dashbord/Add_new_timesheet/" element={<Addnewtimesheet />} />
         <Route path="/Dashbord/Viewstimesheet/" element={<Viewtimesheet />} />

         <Route path="/Addcomment/:comentid" element={<Addcomment />} />
         <Route path="/Addcomment/wizrd" element={<Addemplayess />} />



         <Route path="/Dashbord/Add_employee_type" element={<Addemployeetype />} />
          
         <Route path="/Dashbord/Add_roles" element={<Addroles />} />

         <Route path="/Dashbord/Add_project" element={<Addprojects />} />

         <Route path="/Dashbord/Add_countery" element={<Addcountery />} />
         
         <Route path="/Dashbord/Add_States" element={<Stateslist />} />

         <Route path="/Dashbord/Add_City" element={<Addcity />} />
         <Route path="/Dashbord/Employeetype" element={<Employeetype />} />


         <Route path="/Dashbord/Add_livetype" element={<Add_live_type />} />

         <Route path='/Dashbord/Leave_quata'  element={<Add_leave_quta/>}/>

          
         <Route path="/Sendofferlatter/:Empid" element={<Sendofferlatter />} />

         <Route path="/APPOINTMENTLATER/:Empid" element={<APPOINTMENTLATER />} />
         
         <Route path="/Dashbord/Edit_city/:id" element={<Editcity />} />
         <Route path="/Dashbord/Edit_state/:id" element={<Editstate />} />
         <Route path="/Dashbord/Edit_countery/:id" element={<Editcountery />} />
         <Route path="/Dashbord/Edit_EmployeeType/:id" element={<EditEmployeeType/>} />
         <Route path="/Dashboard/Approvalcomments" element={<Approvalcomments/>}/>
         
         
      </Routes>
    </BrowserRouter>
  );
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
    <App />
  // </React.StrictMode>
);

reportWebVitals();
