import React ,{useEffect,useState}from 'react';
import { useNavigate } from 'react-router-dom';
 import axios from 'axios';
import { useParams } from 'react-router-dom';

import Headerpart from './Comonpart/Headerpart';
const basurl=process.env.REACT_APP_BASE_URL;
export default function Viewcompnay() {
  const navigate = useNavigate(); 

  

  const [fachdatblog ,setFatchblog]=useState('');

  useEffect(() => {
    if (localStorage.getItem("user")== null) {
    
      navigate('/');
    }

    axios.get(basurl+'/api/getcompmay')
    .then(res=>{

      setFatchblog(res.data);
      console.log(res.data);

    })
    .catch(error=> console.log(error));
  }, []); 


 const  HandleEdit=(companyId)=>{
  
  navigate(`/EditCompany/${companyId}`)
    // navigate(`/Dashbord/Edit_Employee/${companyId}`)
  }
  return (
    <>
    <Headerpart/>
    <main id="main" className="main">

    <section className="section">
      <div className="row">
        <div className="col-lg-12">

          <div className="card">
            <div className="card-body">
             
             
              <table className="table datatable">
                <thead>
                  <tr>
                    <th>
                      <b>N</b>ame
                    </th>
                    <th>Employe Code.</th>
                    <th>Password</th>
                    <th>Action</th>
                    
                  </tr>
                </thead>
                <tbody>
                {fachdatblog && fachdatblog.map((item) => (
                  <tr>
                    <td>{item.Name}</td>
                    <td>{item.empcode}</td>
                    <td>{item.password}</td>
                    <td> <button type="button" class="btn btn-default btn-sm"   onClick={()=>HandleEdit(item.id)}>
          <span class="glyphicon glyphicon-edit"></span> Edit  
        </button></td>
                   
                  </tr>
                  ))}
                </tbody>
              </table>
             
            </div>
          </div>

        </div>
      </div>
    </section>

        </main>
    </>
    
  )
}
