
import React, { useEffect, useState } from 'react'
 
import Headerpart from './Comonpart/Headerpart';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import moment from 'moment';

const basurl=process.env.REACT_APP_BASE_URL;
export default function Dashbord() {
  const navigate = useNavigate();
  const[alivedat, setFatchblog]=useState('');
  const Crateid=localStorage.getItem("userAtutc");

  const rolsels= localStorage.getItem("Userrole");

  useEffect(() => {
    //const loggedInUser = localStorage.getItem("user");
    if (localStorage.getItem("user")== null) {
      //console.log(loggedInUser);
      navigate('/');
    }
    axios.get(basurl+`/api/Fetchlivedate/${Crateid}/${rolsels}`)
    .then(res=>{

      setFatchblog(res.data);
    

    })
  }, []);
  return (
    <>
    <Headerpart/>


    <main id="main" className="main">

    <div className="pagetitle">
      <h1>Dashboard</h1>
      <nav>
        <ol className="breadcrumb">
          <li className="breadcrumb-item"><a href="index.html">Home</a></li>
          <li className="breadcrumb-item active">Dashboard</li>
        </ol>
      </nav>
    </div>

    <section className="section dashboard">
      <div className="row">

    
        <div className="col-lg-8">
          <div className="row">

           
           

           

       

         

          
            <div className="col-12">
              <div className="card recent-sales overflow-auto">

               

                <div className="card-body">
                  <h5 className="card-title">Recent Live <span>| Aplication</span></h5>

                  <table className="table table-borderless datatable">
                    <thead>
                      <tr>
                        <th scope="col">#</th>
                        <th scope="col">Apply To</th>
                        <th scope="col">Apply From</th>
                        <th scope="col">Number of day</th>
                        <th scope="col">Status</th>
                      </tr>
                    </thead>
                    <tbody>
                    {alivedat && alivedat.map((item ,i) => (
                      <tr>
                        <th scope="row"><a href="#">#{i + 1}</a></th>
                        <td> {moment(item.todate).format('MMMM Do YYYY')}</td>
                        <td> {moment(item.fromdate).format('MMMM Do YYYY')}</td>
                        <td>{item.totalday}</td>

                        
                        <td>
                          
                          
                          
                          <span className="badge bg-success">Approved</span></td>
                      </tr>

))}
                    
                    </tbody>
                  </table>

                </div>

              </div>
            </div>

         
          

          </div>
        </div>

      
        <div className="col-lg-4">

        
          <div className="card">
           

            <div className="card-body">
              <h5 className="card-title">Recent Activity <span>| Today</span></h5>

              <div className="activity">

                <div className="activity-item d-flex">
                  <div className="activite-label">32 min</div>
                  <i className='bi bi-circle-fill activity-badge text-success align-self-start'></i>
                  <div className="activity-content">
                    Quia quae rerum <a href="#" className="fw-bold text-dark">explicabo officiis</a> beatae
                  </div>
                </div>

                <div className="activity-item d-flex">
                  <div className="activite-label">56 min</div>
                  <i className='bi bi-circle-fill activity-badge text-danger align-self-start'></i>
                  <div className="activity-content">
                    Voluptatem blanditiis blanditiis eveniet
                  </div>
                </div>
                <div className="activity-item d-flex">
                  <div className="activite-label">2 hrs</div>
                  <i className='bi bi-circle-fill activity-badge text-primary align-self-start'></i>
                  <div className="activity-content">
                    Voluptates corrupti molestias voluptatem
                  </div>
                </div>

                <div className="activity-item d-flex">
                  <div className="activite-label">1 day</div>
                  <i className='bi bi-circle-fill activity-badge text-info align-self-start'></i>
                  <div className="activity-content">
                    Tempore autem saepe <a href="#" className="fw-bold text-dark">occaecati voluptatem</a> tempore
                  </div>
                </div>

                <div className="activity-item d-flex">
                  <div className="activite-label">2 days</div>
                  <i className='bi bi-circle-fill activity-badge text-warning align-self-start'></i>
                  <div className="activity-content">
                    Est sit eum reiciendis exercitationem
                  </div>
                </div>

                <div className="activity-item d-flex">
                  <div className="activite-label">4 weeks</div>
                  <i className='bi bi-circle-fill activity-badge text-muted align-self-start'></i>
                  <div className="activity-content">
                    Dicta dolorem harum nulla eius. Ut quidem quidem sit quas
                  </div>
                </div>

              </div>

            </div>
          </div>

       
        

      
         
       

        </div>

      </div>
    </section>

  </main>
    </>
  )
}
