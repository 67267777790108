import React ,{startTransition, useEffect,useState}from 'react';
import { useNavigate } from 'react-router-dom';
 import axios from 'axios';
 import { useParams } from 'react-router-dom';
import moment from 'moment';


 import Headerpart from '../Comonpart/Headerpart';
 const basurl=process.env.REACT_APP_BASE_URL;
 export default function Addcity() {
  const navigate = useNavigate(); 

  

  const [fachdatblog ,setFatchblog]=useState('');
   const [City_name,setName]=useState('');
   const [StateId,setCountryId]=useState('');
   const [editedata ,setEditedata]=useState({});
   const id=useParams() ;
   const Crateid=localStorage.getItem("userAtutc");
  // const [countries, setCountries] = useState([]);
  useEffect(() => {
    //const loggedInUser = localStorage.getItem("user");
    if (localStorage.getItem("user")== null) {
      //console.log(loggedInUser);
      navigate('/');
    }
        axios.get(basurl+`/api/getCityById/${id.id}`)
        .then(res=>{
            setEditedata(res.data);
            setName(res.data.City_name);
            console.log(res.data);

        //   console.log(editedate[0].Name);
        })
    // let eserid=Params();
    // console.warn(eserid);
  }, []);

  function fetchempltype()
  {
    axios.get(basurl+`/api/getAllStates/${Crateid}`)
      //axios.get(`http://localhost:5000/api/getAllState`)
      .then(res=>{
          setFatchblog(res.data.roles);
          setFatchblog(res.data);
          console.log(res.data);
  
      })
      .catch(error=> console.log(error));

  }

 

  useEffect(() => {
    if (localStorage.getItem("user")== null) {
    
      navigate('/');
    }

    fetchempltype();

    

  }, []); 

  


  

const handelsubmit=()=>{
   
  
  axios.put(basurl+`/api/updateCity/${id.id}`,{City_name,StateId})
   
  .then(res => {
    if (res.data) {
      alert("Successfully updated the data");
     setName("")
      setEditedata("")
      setFatchblog("")
      
     
    } else {
      alert("Something went wrong");
    }
  })
  .catch(err => console.log(err));



}

  return (
    <>
    <Headerpart/>
    <main id="main" className="main">

    <section className="section">
      <div className="row">
        <div className="col-lg-12">

          <div className="card">
            <div className="card-body">


            <h5 className="card-title">Add States  List  </h5>

                   
                   
<div className="row mb-3">

<label for="inputEmail3" className="col-sm-2 col-form-label"> Select State </label>
<div className="col-sm-3">
    <select onChange={e=>setCountryId(e.target.value)} className='form-select'>

        <option>--Select Countery--</option>
        {fachdatblog && fachdatblog.map((item ) => (

            <option value={item.id}>{item.State_name}</option>
        ))}

    </select>
      
    </div>


    <label for="inputEmail3" className="col-sm-2 col-form-label">City  Name</label>
    <div className="col-sm-3">
        <input type="text" className="form-control" id="inputText" value={City_name} onChange={e=>setName(e.target.value)}/>
    </div>


    <div className="text-center">

<button type="submit" onClick={handelsubmit} className="btn btn-primary">Submit</button>
   
</div>
</div>
             
             
              {/* <table className="table datatable">
                <thead>
                  <tr>
                    <th>
                      S.N
                    </th>
                    <th>Country   Name : </th>

                    <th>States Name : </th>
                 
                    <th>Action</th>
                    
                  </tr>
                </thead>
                <tbody>
                  
                {fachdatblog && fachdatblog.map((item ,i) => (
                 
                 
                  <tr>
                    <td>{i + 1}</td>
                    <td> {item.name}
                        
                       </td>

                       <td> {item.State_name}
                        
                        </td>
                 
                    <td>{item.Status=="0"?
                    <span style={{background:"red", color:"#fff",padding:"5px"}}>Abase</span>
                    
                    :
                    <span style={{background:"green", color:"#fff",padding:"5px"}}>Present </span>
                }


                { }
                    </td>
                    
                   
                  </tr>
                  ))}
                </tbody>
              </table> */}
             
            </div>
          </div>

        </div>
      </div>
    </section>

        </main>
    </>
    
  )
}
