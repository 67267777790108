import React, {useEffect,useState} from 'react'
 import Headerpart from './Comonpart/Headerpart';
 import { useNavigate } from 'react-router-dom';
 import axios from 'axios';
 import moment from 'moment';
 import { useParams } from 'react-router-dom';
 const basurl=process.env.REACT_APP_BASE_URL;
 export default function Holidaylive() {
    const navigate = useNavigate();

    const [name,setName]=useState('');
     const [setlivedate,setLivedate]=useState('');
     const [livelist,setLivelist]=useState('');
    
     const userrol='Com';
  useEffect(() => {
  
    if (localStorage.getItem("user")== null) {
      //console.log(loggedInUser);
      navigate('/');
    }
    const Crateid=localStorage.getItem("userAtutc");

    axios.get(basurl+`/api/Livelist/${Crateid}`)
    //axios.get('/api/Livelist')
    .then(res=>{


        setLivelist(res.data);
      //console.log(res.data);

    })
    .catch(error=> console.log(error));
  }, []);

  const handelsubmit=()=>{
     
        const Crateid=localStorage.getItem("userAtutc");
      
        axios.post(basurl+'/api/creader/newlive',{name,setlivedate,Crateid})
   
          
         .then(res=>{
          
             if(res.data>0)
             {
                 alert("Succes full Add the data"); 
                 window.location.reload(false);
                
             }

             else
             {

                 alert("Soming Mesiing ")
             }

         })
         .catch(err=>console.log(err));



    }
  return (
    <>
    <Headerpart/>
    <main id="main" className="main">

<section className="section">
    <div className="row">
        <div className="col-lg-12">

            <div className="card">
                <div className="card-body">
                    <h5 className="card-title">Add New  holiday List </h5>

                   
                   
                        <div className="row mb-3">
                            <label for="inputEmail3" className="col-sm-2 col-form-label">Live  Name</label>
                            <div className="col-sm-10">
                                <input type="text" className="form-control" id="inputText" onChange={e=>setName(e.target.value)} required/>
                            </div>
                        </div>
                        <div className="row mb-3">
                            <label for="inputEmail3" className="col-sm-2 col-form-label"> Date  </label>
                            <div className="col-sm-10">
                                <input type="date" className="form-control" id="inputEmail" onChange={e=>setLivedate(e.target.value)} required/>
                            </div>
                        </div>
                       
       

                        <div className="text-center">

                        <button type="submit" onClick={handelsubmit} className="btn btn-primary">Submit</button>
                           
                        </div>
                   
                </div>
            </div>



            <table className="table datatable">
                <thead>

              
                  <tr>
                    <th>
                     Holiday Name
                    </th>
                    <th>Date.</th>
                   
                  </tr>
                </thead>
                <tbody>
                {livelist && livelist.map((item) => (
                  <tr>
                    <td>{item.livename}</td>
                    <td>  {moment(item.livedate).format('Do MMMM YYYY')} </td>
                    
                  </tr>
                  ))}
                </tbody>
              </table>
          

        </div>

      
    </div>
</section>

    </main>
    </>
  )
}
