import React, {useEffect,useState} from 'react'
 //import Headerpart from './Comonpart/Headerpart'
  import Headerpart from './Comonpart/Headerpart';
 import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import {generatePDF}  from './helpers/PdfFunctions';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import converter from 'number-to-words';
const basurl=process.env.REACT_APP_BASE_URL;
var sum = 0;

var anualseley = 0;

// const logo = require('../../public/Images/logo.png');


//  import logo from '../../public/Images/logo.png';

export default function Sendofferlatter() {
  const navigate = useNavigate();
  const Empid=useParams();

  const [employeemaster ,setEmployeemaster]=useState('');

  const [fechuserdate ,setFechuserdate]=useState('');
   const[empmastrpf ,setEmpmastrpf]=useState('');
  useEffect(() => {
    if (localStorage.getItem("user")== null) {
    
      navigate('/');
    }
    const Crateid=localStorage.getItem("userAtutc");
   // console.log(Empid);

    Fechempdata(Empid);
  }, []);

   function Fechempdata(Empid){

    axios.get(basurl+`/api/fetchemployeemaster/${Empid.Empid}`)
     .then(res=>{
      setEmployeemaster(res.data);

       console.log(res.data);
       let emp_id=res.data[0].empcode;

       fechpfdata(emp_id)
      

     })
     .catch(error=> console.log(error));

   }

    function fechpfdata(emp_id)
    {
        axios.get(basurl+`/api/Fetchpfdata/${emp_id}`)
        .then(resdat=>{
   
         setEmpmastrpf(resdat.data);


          console.log(resdat.data);

        
          let aser=resdat.data;

          //console.log(aser);

          for (let i = 0; i < aser.length; i++) {
            sum += aser[i].amount ;

            anualseley+=aser[i].amount*12;
          }

           console.log(sum);


    
                 
        })
 
        
        .catch(error=> console.log(error));
 

    }

    const  Offerlater= async ()=>{


       let formData = {}
     //console.log(employeemaster[0]?.compnayemail)
     formData['from'] =employeemaster[0]?.email;
     formData['to'] = employeemaster[0]?.compnayemail;
     formData['cc'] ='abhay@crimsonssystems.com'
     formData['subject'] ='Offer Letter  From'+employeemaster[0]?.companyname;

   
    
     let element = document.createElement("div");
     let items = empmastrpf && empmastrpf.map((item, i) => {
         return (
             `<tr>
                 <td>${item?.Pay_name}</td>
                 <td>${item?.amount}</td>
                 <td>${item?.amount}</td>
             </tr>`
         )
     })

     formData['body'] = ` 
     
     <div class="containe  mt-3">
   
     <div class="row">
         <div class="col-9"> 
         
         <strong>
 
     
 <span className="pcs-customer-name">
 
 <label>Ref: </label>SAINNOV/HR/${moment(employeemaster[0]?.joindate).format('YYYY')}/${moment(employeemaster[0]?.joindate).format('MMMM')}/${moment(employeemaster[0]?.joindate).format('D')}
 <br/>
   <label> Name :  </label>
          &nbsp;${employeemaster[0]?.Name}
   <br />
   <label>Address :</label>
   &nbsp;${employeemaster[0]?.Address}
   <br />
 
   <label>Email:</label>
 
   &nbsp;${employeemaster[0]?.email}
   
   <br />
  
   <label>Phone:</label>
   &nbsp;${employeemaster[0]?.contact_numer}
   <br />
  
 </span>
 </strong>
 
 </div>
         <div class="col-3"><span class="float-right">Date :${moment(employeemaster[0]?.joindate).format('DD MMMM YYYY')}</span></div>
     </div>
 
     </div>

   <div class="me-1 border-dark "  >
   <div class="row ">
       <center>
           <h1 >Offer Letter</h1> 
       </center>
   </div> 
</div> 

<div >
<p> Dear ${employeemaster[0]?.Name}</p>
<p>Based on the interaction we have had with you, ${employeemaster[0]?.companyname}, is pleased to release this offer for the role of Manager in Business Development, your designation will be  <strong>“${employeemaster[0]?.Posin_name}” </strong>. Given below are the applicable Terms and conditions of this employment</p>

<p>1. Your compensation package has been set as Rs.${anualseley}/- (  ${converter.toWords(anualseley || 0)} only) per annum, total cost to the company. The Compensation will be revised after completing One Year as Per the employee's performance. The detailed break-up is attached herewith in Annexure- A</p>


<p>2. You are requested to furnish the following documents to our office before joining by mail: -<br/>

<li data-list-text="•">Educational / Technical / Professional Qualifications <br/></li>

<li data-list-text="•">Experience Letter/acceptance of resignation from previous employer <br/></li>

<li data-list-text="•">Details of the last remuneration drawn <br/></li>

<li data-list-text="•">Certificate of Medical Fitness <br/></li>

<li data-list-text="•">Residence Proof (Passport /Mobile Bill/Telephone Bill/ Electricity Bill / Bank Statement) <br/></li>

<li data-list-text="•">Photocopy of Pan Card</li></p>

<p >3. On the date of joining, you are requested
to bring: -</p>

<li data-list-text="•">
    Original
        documents mentioned above.
</li>
<li data-list-text="•">
   2 Passport size
        photographs
  
</li>
<br/>
<p> 4. You are requested to join on or before ${moment(employeemaster[0]?.joindate).format('Do MMMM YYYY')} at the following location: -<br></br>
${employeemaster[0]?.companyaddress}</p>
<br/>

<p>
5. Please sign and return the duplicate copy of this letter as a token of your acceptance, Please confirm your joining on or before ${moment(employeemaster[0]?.joindate).format('Do MMMM YYYY')}.
</p>
<br/>

<p>6. A detailed appointment letter will be issued upon your joining, subject to submission of all the documents mentioned above, a review of your role will be done as per company policy.</p>           
<br/>

<p>7. We look forward to you being part of the   ${employeemaster[0]?.companyname}   Family.

</p>  



<div class="me-1 border-dark "  >
<div class="row ">
<center>
 <h2 >Annexure A</h2> 
</center>
</div> 
</div>
<strong>1. Employment Grade : BD</strong><br/>
<strong>2. Designation  :  ${employeemaster[0]?.Posin_name} </strong><br/>
<strong>3. Salary :  ${sum}   (  ${converter.toWords(sum || 0)} only) per Month</strong><br/>


<table className="table datatable">

<thead>
<tr>
<th style="background: rgb(49, 61, 79); color: rgb(255, 255, 255);">
SALAY COMPONENTS
</th>

<th style="background: rgb(49, 61, 79); color: rgb(255, 255, 255);">
AMOUNT IN INR PER MONTH
</th>
<th style="background: rgb(49, 61, 79); color: rgb(255, 255, 255);">AMOUNT IN INR PER ANNUM</th>
</tr>
</thead>

<tbody>

<tr>
<td  style="background: rgb(172, 184, 201);">TOTAL FIXED PAY</td>
<td style="background: rgb(172, 184, 201);"> 

</td>
<td style="background: rgb(172, 184, 201);"></td>
</tr>


${items || ''}

</tbody>

<tfoot>

<tr  style="background: rgb(46, 83, 149); color: rgb(255, 255, 255);">
<td style="background: rgb(46, 83, 149); color: rgb(255, 255, 255);">COST TO COMPANY</td>
<tdstyle="background: rgb(46, 83, 149); color: rgb(255, 255, 255);">   ${sum}/-

</tdstyle=>
<td style="background: rgb(46, 83, 149); color: rgb(255, 255, 255);">${anualseley}/-</td>
</tr></tfoot>

</table>

<strong>Swapnill Sijaria</strong><br/>
<strong>CTO (Chief Technology Officer)</strong>

<br>
</br>
<br>
</br>


<strong> ${employeemaster[0]?.companyname}</strong><br/>
Contact us via email:  <strong>${employeemaster[0]?.compnayemail}</strong><br/>
Phone Number: <strong>+91-${employeemaster[0]?.compaycontcatnumabe}</strong><br/>
Adress:  <strong>${employeemaster[0]?.companyaddress}</strong><br/>
</div>`;

     element.innerHTML = `
    
     <div class="containe  mt-3">
   
     <div class="row">
         <div class="col-9"> 
         
         <strong>
 
     
 <span className="pcs-customer-name">
 
 <label>Ref: </label>SAINNOV/HR/${moment(employeemaster[0]?.joindate).format('YYYY')}/${moment(employeemaster[0]?.joindate).format('MMMM')}/${moment(employeemaster[0]?.joindate).format('D')}
 <br/>
   <label> Name :  </label>
          &nbsp;${employeemaster[0]?.Name}
   <br />
   <label>Address :</label>
   &nbsp;${employeemaster[0]?.Address}
   <br />
 
   <label>Email:</label>
 
   &nbsp;${employeemaster[0]?.email}
   
   <br />
  
   <label>Phone:</label>
   &nbsp;${employeemaster[0]?.contact_numer}
   <br />
  
 </span>
 </strong>
 
 </div>
         <div class="col-3"><span class="float-right">Date : ${moment(employeemaster[0]?.joindate).format('DD MMMM YYYY')}</span></div>
     </div>
 
     </div>

   <div class="me-1 border-dark "  >
   <div class="row ">
       <center>
           <h1 >Offer Letter</h1> 

       </center>
   </div> 
</div> 

<div>
<p>Dear  ${employeemaster[0]?.Name}</p>
<p>Based on the interaction we have had with you, ${employeemaster[0]?.companyname}, is pleased to release this offer for the role of Manager in Business Development, your designation will be  <strong>“${employeemaster[0]?.Posin_name}” </strong>. Given below are the applicable Terms and conditions of this employment</p>

<p>1. Your compensation package has been set as Rs.${anualseley}/- (  ${converter.toWords(anualseley || 0)} only) per annum, total cost to the company. The Compensation will be revised after completing One Year as Per the employee's performance. The detailed break-up is attached herewith in Annexure- A</p>


<p>2. You are requested to furnish the following documents to our office before joining by mail: -<br/>

<li data-list-text="•">Educational / Technical / Professional Qualifications <br/></li>

<li data-list-text="•">Experience Letter/acceptance of resignation from previous employer <br/></li>

<li data-list-text="•">Details of the last remuneration drawn <br/></li>

<li data-list-text="•">Certificate of Medical Fitness <br/></li>

<li data-list-text="•">Residence Proof (Passport /Mobile Bill/Telephone Bill/ Electricity Bill / Bank Statement) <br/></li>

<li data-list-text="•">Photocopy of Pan Card</li></p>

<p >3. On the date of joining, you are requested
to bring: -</p>

<li data-list-text="•">
    Original
        documents mentioned above.
</li>
<li data-list-text="•">
   2 Passport size
        photographs
  
</li>
<br/>
<p> 4. You are requested to join on or before ${moment(employeemaster[0]?.joindate).format('Do MMMM YYYY')} at the following location: -<br></br>
${employeemaster[0]?.companyaddress}</p>
<br/>

<p>
5. Please sign and return the duplicate copy of this letter as a token of your acceptance, Please confirm your joining on or before ${moment(employeemaster[0]?.joindate).format('Do MMMM YYYY')}.
</p>
<br/>

<p>6. A detailed appointment letter will be issued upon your joining, subject to submission of all the documents mentioned above, a review of your role will be done as per company policy.</p>           
<br/>

<p>7. We look forward to you being part of the   ${employeemaster[0]?.companyname}   Family.

</p>  



<div class="me-1 border-dark "  >
<div class="row ">
<center>
 <h2 >Annexure A</h2> 
</center>
</div> 
</div>
<strong>1. Employment Grade : BD</strong><br/>
<strong>2. Designation  :  ${employeemaster[0]?.Posin_name} </strong><br/>
<strong>3. Salary :  ${sum}   (  ${converter.toWords(sum || 0)} only) per Month</strong><br/>


<table className="table datatable">

<thead>
<tr>
<th style="background: rgb(49, 61, 79); color: rgb(255, 255, 255);">
SALAY COMPONENTS
</th>

<th style="background: rgb(49, 61, 79); color: rgb(255, 255, 255);">
AMOUNT IN INR PER MONTH
</th>
<th style="background: rgb(49, 61, 79); color: rgb(255, 255, 255);">AMOUNT IN INR PER ANNUM</th>
</tr>
</thead>

<tbody>

<tr>
<td  style="background: rgb(172, 184, 201);">TOTAL FIXED PAY</td>
<td style="background: rgb(172, 184, 201);"> 

</td>
<td style="background: rgb(172, 184, 201);"></td>
</tr>
${items || ''}
</tbody>

<tfoot>

<tr   style="background: rgb(46, 83, 149); color: rgb(255, 255, 255);">
<td style="background: rgb(46, 83, 149); color: rgb(255, 255, 255);">COST TO COMPANY</td>
<td  style="background: rgb(46, 83, 149); color: rgb(255, 255, 255);">   ${sum}/-

</td>
<td  style="background: rgb(46, 83, 149); color: rgb(255, 255, 255);">${anualseley}/-</td>
</tr></tfoot>

</table>

<strong>Swapnill Sijaria</strong><br/>
<strong>CTO (Chief Technology Officer)</strong>

<br>
</br>
<br>
</br>
<strong> ${employeemaster[0]?.companyname}</strong><br/>
Contact us via email:  <strong>${employeemaster[0]?.compnayemail}</strong><br/>
Phone Number: <strong>+91-${employeemaster[0]?.compaycontcatnumabe}</strong><br/>
Adress:  <strong>${employeemaster[0]?.companyaddress}</strong><br/>
</div>
  `

    let pdf = await generatePDF(element)
     formData['attachment'] = [pdf]


      //console.log(formData);
     //let baseurl='http://localhost:5000/api';

     axios({
      method: 'post',
      url: basurl + "/email",
      headers: {
          'Content-Type': 'multipart/form-data'
      },
      data: formData
  }).then((res) => {
     // setLoading(false)
      console.log(res.data)
      alert("Mail Sent Successfully")
  }).catch((err) => {
     // setLoading(false)
      console.log(err);
      alert(err.response.data)
  })


    
  }
        
      
  return (
    <>
    <Headerpart/>

    <main id="main" className="main">

    <section className="section">
      <div className="row">
        <div className="col-lg-12">

          <div className="card">
            <div className="card-body">

              <div className='col-lg-10 ms-lg-5 p-5'>


            
                <div  style={{with:"100%"}}>
             
           <img  style={{width:"300px", marginLeft:"-25px"}} src='/Images/logo.png' />
           </div>



           <div class="containe  mt-3">
   
    <div class="row">
        <div class="col-9"> 
        
        <strong>

    
<span className="pcs-customer-name">

<label>Ref: </label>SAINNOV/HR/{moment(employeemaster[0]?.joindate).format('YYYY')}/{moment(employeemaster[0]?.joindate).format('MMMM')}/{moment(employeemaster[0]?.joindate).format('D')}
<br/>
  <label> Name :  </label>
         &nbsp;{employeemaster[0]?.Name}
  <br />
  <label>Address :</label>
  &nbsp;{employeemaster[0]?.Address}
  <br />

  <label>Email:</label>

  &nbsp;{employeemaster[0]?.email}
  
  <br />
 
  <label>Phone:</label>
  &nbsp;{employeemaster[0]?.contact_numer}
  <br />
 
</span>
</strong>

</div>
        <div class="col-3"><span class="float-right">Date : {moment(employeemaster[0]?.joindate).format('DD MMMM YYYY')}</span></div>
    </div>

    </div>
     
   <div class="me-1 border-dark "  >
         
             <center>
                 <h1 ><u>Offer Letter</u></h1> 

               
             </center>
          
     </div> 
     
 
          
           

            <div  >

            <p>Dear {employeemaster[0]?.Name}</p><br/>
                <p>Based on the interaction we have had with you, {employeemaster[0]?.companyname}, is pleased to release this offer for the role of Manager in Business Development, your designation will be  <strong>“{employeemaster[0]?.Posin_name}” </strong>. Given below are the applicable Terms and conditions of this employment</p>

    <p>1. Your compensation package has been set as Rs.{anualseley}/- (  {converter.toWords(anualseley || 0)} only) per annum, total cost to the company. The Compensation will be revised after completing One Year as Per the employee's performance. The detailed break-up is attached herewith in Annexure- A</p>
            
            
            <p>2. You are requested to furnish the following documents to our office before joining by mail: -<br/>

            <li data-list-text="•">Educational / Technical / Professional Qualifications <br/></li>

            <li data-list-text="•">Experience Letter/acceptance of resignation from previous employer <br/></li>

            <li data-list-text="•">Details of the last remuneration drawn <br/></li>

            <li data-list-text="•">Certificate of Medical Fitness <br/></li>

            <li data-list-text="•">Residence Proof (Passport /Mobile Bill/Telephone Bill/ Electricity Bill / Bank Statement) <br/></li>

            <li data-list-text="•">Photocopy of Pan Card</li></p>

            <p >3. On the date of joining, you are requested
                to bring: -</p>
               
                <li data-list-text="•">
                    Original
                        documents mentioned above.
                </li>
                <li data-list-text="•">
                   2 Passport size
                        photographs
                  
                </li>
    <br/>
                <p> 4. You are requested to join on or before {moment(employeemaster[0]?.joindate).format('Do MMMM YYYY')} at the following location: -<br></br>
                {employeemaster[0]?.companyaddress}</p>
<br/>

<p>
5. Please sign and return the duplicate copy of this letter as a token of your acceptance, Please confirm your joining on or before {moment(employeemaster[0]?.joindate).format('Do MMMM YYYY')}.
</p>
<br/>

 <p>6. A detailed appointment letter will be issued upon your joining, subject to submission of all the documents mentioned above, a review of your role will be done as per company policy.</p>           
         <br/>
            
         <p>7. We look forward to you being part of the   {employeemaster[0]?.companyname}   Family.

</p>  



<div class="me-1 border-dark "  >
         <div class="row ">
             <center>
                 <h2 ><u>Annexure A</u></h2> 
             </center>
         </div> 
     </div>
     <strong>1. Employment Grade : BD</strong><br/>
     <strong>2. Designation  :  {employeemaster[0]?.Posin_name} </strong><br/>
     <strong>3. Salary :  {sum}   (  {converter.toWords(sum || 0)} only) per Month</strong><br/>


     <table className="table datatable">

        <thead>
            <tr>
                <th style={{ background:" rgb(49, 61, 79)", color:"#FFF"}}>
                SALAY COMPONENTS
                </th>

                <th style={{ background:" rgb(49, 61, 79)", color:"#FFF"}}>
                AMOUNT IN INR PER MONTH
                </th>
                <th style={{ background:" rgb(49, 61, 79)", color:"#FFF"}}>AMOUNT IN INR PER ANNUM</th>
            </tr>
        </thead>

        <tbody>

            <tr>
                <td  style={{ background:" rgb(172, 184, 201)"}}>TOTAL FIXED PAY</td>
                <td  style={{ background:" rgb(172, 184, 201)"}}> 

                </td>
                <td  style={{ background:" rgb(172, 184, 201)"}}></td>
            </tr>

          
        {empmastrpf && empmastrpf.map((item) => (
        
                  <tr>
                    <td>{item.Pay_name}</td>
                    <td> {item.amount} </td>

                    <td> {item.amount*12} </td>
                    
                  </tr>
                  ))}

        </tbody>

        <tfoot>

<tr  style={{height:"100px", background:" rgb(46, 83, 149)"}}>
                <td style={{ background:" rgb(46, 83, 149)", color:"#FFF"}}>COST TO COMPANY</td>
                <td style={{ background:" rgb(46, 83, 149)", color:"#FFF"}}>   {sum}/-

                </td>
                <td style={{ background:" rgb(46, 83, 149)", color:"#FFF"}}>{anualseley}/-</td>
            </tr></tfoot>

        </table>

        <strong>Swapnill Sijaria</strong><br/>
        <strong>CTO (Chief Technology Officer)</strong>

        <br>
        </br>
        <br>
        </br>

            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
        <strong> {employeemaster[0]?.companyname}</strong><br/>
        Contact us via email:  <strong>{employeemaster[0]?.compnayemail}</strong><br/>
        Phone Number: <strong>+91-{employeemaster[0]?.compaycontcatnumabe}</strong><br/>
        Adress:  <strong>{employeemaster[0]?.companyaddress}</strong><br/>
            </div>

            <button
                        style={{
                            backgroundColor: "orangered",
                            border: "none",
                            marginRight: ".5vw",
                             float:"right"
                        }}
                        type="submit"
                        className="btn btn-primary"
                        onClick={()=>Offerlater()}
                    >
                        Send
                    </button>
            </div>
         </div>
         </div>
        </div>
      </div>
    </section>

        </main>
    </>
  )
}
