import React, {useEffect,useState} from 'react'
 //import Headerpart from '../Comonpart/Headerpart';
 import { useNavigate } from 'react-router-dom';
 import axios from 'axios';
  import Headerpart from '../Comonpart/Headerpart';
  import { useParams } from 'react-router-dom';
  import moment from 'moment';
  const basurl=process.env.REACT_APP_BASE_URL;
export default function Addcomment() {
    const navigate = useNavigate();
    
    const Crateid=localStorage.getItem("userAtutc");
    const [fetchproject,setFetchproject]=useState('');

   // const [projectid,setpPojectid]=useState('');
    const [status,setStatus]=useState('');
    const [theading,setTheading]=useState('');
    const [workinhouese,setWorkinhouese]=useState('');
    const [commetheading,setCommetheading]=useState('');

    const [sttusesdata,setSttusesdata]=useState('');
    const [commentdata,setcommentdata]=useState('');
    

    const comentid=useParams();
    const  projectid=comentid.comentid
    const rolsels= localStorage.getItem("Userrole");
    
    
  function fechststuss()
  {
    axios.get(basurl+`/api/Fetchststus`)
    .then(res=>{
        setSttusesdata(res.data); 
    })
    .catch(error=> console.log(error));
  }
    

  function fetchdata()
      {

        axios.get(basurl+`/api/FetchCommentdata/${comentid.comentid}/${Crateid}/${rolsels}`)
        .then(res=>{
    
            setcommentdata(res.data);
    
        })
      }

  function fetchdatcomment()
   {
    axios.get(basurl+`/api/Fetchcomment/${comentid.comentid}/${Crateid}`)
    .then(res=>{
        if(res.data.length>0)
        {
           setTheading(res.data[0].Heading);
        setFetchproject(res.data);
           // console.log(res.data[0].Heading);
        
        }
         else{

             alert("Something messing");
         }

    })
  }
  
  useEffect(() => {
    if (localStorage.getItem("user")== null) {
      navigate('/');
    }
    
    fechststuss();
    if(rolsels=="EMP")
     {
    fetchdatcomment();
     }
     
    fetchdata();
     
    
  }, []);

  const handelsubmit=()=>{ 
        axios.post(basurl+'/api/Addcomment',{projectid,workinhouese,status,Crateid,commetheading}) 
         .then(res=>{
          
             if(res.data>0)
             {
                 alert("Succes full Add Comment"); 
                 
                 fetchdata();
             }

             else
             {

                 alert("Soming Mesiing ")
             }

         })
         .catch(err=>console.log(err));

    }


    const changstatus=(id)=>
    {
       

        if(rolsels=='Com')
        {

           axios.get(basurl+`/api/Aprvalstatus/${id}`)
           .then(res=>{


             console.log(res.data.affectedRows);

             if(res.data.affectedRows=1)
             {
              fetchdata();
             // fetchdatcomment();
             }
              else{

                 alert("Soomething messing");
              }
       
       
           })
     
        }
         else
         {

            alert("Not authorized  To peform this Action plese contcat yor compnay Admin");
         }

    }

  return (
    <>
    <Headerpart/>
    <main id="main" className="main">

<section className="section">
    <div className="row">
        <div className="col-lg-12">
        {rolsels=="EMP"&&
  <>
            <div className="card">
                <div className="card-body">
                <div class="row">
        
           
        <div class="col-md-4">
                <div class="form-group">
                    <label for="projectName">Timesheet Heading :</label>
                    <p>{theading}</p>
                </div>
                </div>

                <div class="col-md-4">
                <div class="form-group">
                    <label for="projectName">Add Working Hours :</label>
                    <input type="number" class="form-control"  min={0} onChange={e=>setWorkinhouese(e.target.value)}    required/>
                </div>
                </div>
                <div class="col-md-4">
                <div class="form-group">
                    <label for="heading" > Status :</label>
                    <select class="form-select" id="projectName"   onChange={e=>setStatus(e.target.value)}>
                        <option value={0}>--Select Status --</option>
                    {sttusesdata && sttusesdata.map((item ,i) => (
                        <option value={item.id}>{item.name}</option>
                        ))}
                       
                    </select>
                </div>
                        </div>

                       
                     


                        <div class="col-md-4">
                <div class="form-group">
                    <label for="remarks">Add Comment</label>
                    <textarea class="form-control" id="remarks" rows="4" required  onChange={e=>setCommetheading(e.target.value)}    ></textarea>
                </div>
            
        </div>


        <div class="col-md-4">
                <div class="form-group">
                    <label for="remarks">Add Document</label>

                  <input type='file'/>
                    <textarea class="form-control" id="remarks" rows="4" required  onChange={e=>setCommetheading(e.target.value)}    ></textarea>
                </div>
            
        </div>
         
        <div class="col-md-6 p-lg-5">
                    <button type="submit" class="btn btn-primary" onClick={handelsubmit}>Submit</button>
                </div>
            </div> 

         
    </div>
                   
                </div>
                </>
}






                <table className="table datatable"  style={{border:'1px solid'}}>
                <thead>
                <tr>
          <th scope="col">S.No</th>
          <th scope="col">Comment Date</th>
          <th scope="col">Working Hours </th>
          <th scope="col">Status</th>
          <th scope="col">Comment</th>
           <th scope="col">Approval Status</th>
        </tr>
                </thead>
                <tbody>  
                {commentdata && commentdata.map((item ,i) => (
                  <tr>
                    <td>{i + 1}</td>
                    <td> {moment(item.coment_date).format('MMMM Do YYYY')}</td>
                    <td> {item.work_hourse} HR</td>
                    <td>  {item.name}</td>
                    <td>  {item.Coomet_text}</td>

                    {rolsels=="EMP"&&
  <>
                    <td>{item.Approval_status=="0"?
                  
                      <a href="#" onClick={changstatus.bind(this, item.id)}>
                    <span    style={{background:"red", color:"#fff",padding:"5px"}} >Pending    </span>
                    </a>
                    
                    :
                    <span style={{background:"green", color:"#fff",padding:"5px"}}>Aproved    </span>
                }


                { }
                    </td>
                    </>

                    
}


                    
                    {rolsels=="Com"&&
  <>
                                    <td>{item.Approval_status=="0"?
                  
                      <a href="#" onClick={changstatus.bind(this, item.id)}>
                    <span    style={{background:"red", color:"#fff",padding:"5px"}} >Pending  Applay By   </span>
                    </a>
                    
                    :
                    <span style={{background:"green", color:"#fff",padding:"5px"}}>Aproved    </span>
                }

</td>




                { }
                   
                    
                   </>

                    
}
                   
  
                   
                  </tr>
                  ))}
                </tbody>
              </table>
            </div>
          
          

        </div>

    
</section>

    </main>
    </>
  )
}
