import React, {useEffect,useState} from 'react'
 import Headerpart from './Comonpart/Headerpart';
 import { useNavigate } from 'react-router-dom';
 import axios from 'axios';
 import moment from 'moment';
 import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from '@fullcalendar/daygrid'
const basurl=process.env.REACT_APP_BASE_URL;
 export default function Holidaylive() {
    const navigate = useNavigate();
     const [livelist,setLivelist]=useState('');
    
   
  useEffect(() => {
  
    if (localStorage.getItem("user")== null) {
      //console.log(loggedInUser);
      navigate('/');
    }
    const Crateid=localStorage.getItem("userAtutc");

    const Alldatesss=localStorage.getItem("Alluser");
   
    axios.get(basurl+`/api/Livelist/${Alldatesss}`)
  //  axios.get('/api/Livelist')
    .then(res=>{

           

        setLivelist(res.data);
     

    })
    .catch(error=> console.log(error));
  }, []);
  
  const events = [
    
  ];

  //  var dates='1/2/2024';
  // var a = new Date(dates);
  var dat="";
  //var cona="";

  {livelist && livelist.map((item) => (
    dat=moment(item.livedate).format('M/D/YYYY'),

    
   

    
    events.push({
             title: item.livename,
            start: new Date(dat),
            end: new Date(dat),
            color: 'red',
            backgroundColor: '#74AAEB',
            borderColor: 'red',
            textColor: 'red',
            eventColor: 'red',
            
         })
        ))}

 
 // console.log(events);
   //console.log(a);
  //  console.log(dat);

  return (
    <>
    <Headerpart/>
    <main id="main" className="main">

<section className="section">
    <div className="row">
        <div className="col-lg-12">

        <FullCalendar
        plugins={[dayGridPlugin]}
        initialView='dayGridMonth'
        weekends={false}
        events={events}
        eventContent={renderEventContent}
        
      />  
          

        </div>

      
    </div>
</section>

    </main>
    </>
  )
}

function renderEventContent(eventInfo) {
    return (
      <>
      <div  style={{background:'red', color:'white', minHeight:'100px', minWidth:'250px',padding:'80px'}}>
        <i style={{ fontSize:'30px'}}>{eventInfo.event.title}</i>
        </div>
      </>
    )
  }
