import React, {useEffect,useState} from 'react'
import Headerpart from '../Comonpart/Headerpart';
 import { useNavigate } from 'react-router-dom';
 import axios from 'axios';
  import moment from 'moment';
  const basurl=process.env.REACT_APP_BASE_URL;
export default function Viewtimesheet() {
    const navigate = useNavigate();

    const [name,setName]=useState('');
     const [todate,setTodate]=useState('');
     const [fromdate,setFromdate]=useState('');

     const[alivedat, setFatchblog]=useState('');

     const Crateid=localStorage.getItem("userAtutc");

     const rolsels= localStorage.getItem("Userrole");

      function fetchdata()
      {

        axios.get(basurl+`/api/Fetchtimsheet/${Crateid}/${rolsels}`)
        .then(res=>{
    
          setFatchblog(res.data);
    
        })
      }

  useEffect(() => {
   
    if (localStorage.getItem("user")== null) {
     
      navigate('/');
    }
    fetchdata();
     
  }, []);

     const changstatus=(id)=>
     {
        

         if(rolsels=='Com')
         {

            axios.get(basurl+`/api/Aprvalenoew/${Crateid}/${id}`)
            .then(res=>{
                    if(res.data.changedRows>0)
                    {
                        alert("successful  Aproved  ");
                        fetchdata();
                    }
                      else
                      {

                        alert(res.data.info);
                      }
        
            })
      
         }
          else
          {

             alert("Not authorized  To peform this Action plese contcat yor compnay Admin");
          }

          
     }

     const  HandleEdit=(companyId)=>{
        navigate(`/Addcomment/${companyId}`)
      }
  return (
    <>
    <Headerpart/>
    <main id="main" className="main">

<section className="section">
    <div className="row">
        <div className="col-lg-12">
            <table className="table datatable">
                <thead>
                <tr>
          <th scope="col">S.No</th>
          <th scope="col">Heading</th>
          <th scope="col">Start Date</th>
          <th scope="col">End Date</th>
          <th scope="col">Status</th>
          <th scope="col">Project</th>
          <th scope="col">Action</th>
        </tr>
                </thead>
                <tbody>  
                {alivedat && alivedat.map((item ,i) => (
                  <tr>
                    <td>{i + 1}</td>
                    <td> {item.Heading}</td>
                    <td>  {moment(item.Start_Date).format('MMMM Do YYYY')}</td>
                    <td>  {moment(item.End_Date).format('MMMM Do YYYY')}</td>
                    <td>  {item.name}</td>
                    <td>  {item.Project_name}</td>
                    {rolsels=="EMP"&&
  <>
  {item.aprovalststus=="0"?
                    <td>  <button type="button" class="btn  btn-warning">Approval pending</button></td>
:
<td>  <a href='#' class="btn btn-success"  onClick={()=>HandleEdit(item.id)}>Add Comment </a>
    
    </td>
}
                   
                    </>
}
{rolsels=="Com"&&
  <>
 {item.aprovalststus=="0"?
 
                    <td>  
                        
                        <a  href='#' onClick={changstatus.bind(this, item.id)} class="btn  btn-warning">Approved now</a>
                        
                       </td>
:
<td>  <a href='#' class="btn btn-success"  onClick={()=>HandleEdit(item.id)}>View Comment </a></td>
}
                   
                  

</>
}
                   
  
                   
                  </tr>
                  ))}
                </tbody>
              </table>

        </div>

      
    </div>
</section>

    </main>
    </>
  )
}
