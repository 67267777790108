import React, {useEffect,useState} from 'react'
 //import Headerpart from './Comonpart/Headerpart'
  import Headerpart from './Comonpart/Headerpart';
 import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import {generatePDF}  from './helpers/PdfFunctions';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import converter from 'number-to-words';

 import {generateAndSavePDF} from './helpers/PdfFunctions';
 const basurl=process.env.REACT_APP_BASE_URL;

var sum = 0;

var anualseley = 0;

export default function APPOINTMENTLATER() {
  const navigate = useNavigate();
  const Empid=useParams();

  const [employeemaster ,setEmployeemaster]=useState('');

  const [fechuserdate ,setFechuserdate]=useState('');
   const[empmastrpf ,setEmpmastrpf]=useState('');
  useEffect(() => {
    if (localStorage.getItem("user")== null) {
    
      navigate('/');
    }
    const Crateid=localStorage.getItem("userAtutc");
   // console.log(Empid);

    Fechempdata(Empid);
  }, []);

   function Fechempdata(Empid){

    axios.get(basurl+`/api/fetchemployeemaster/${Empid.Empid}`)
     .then(res=>{
      setEmployeemaster(res.data);

       console.log(res.data);
       let emp_id=res.data[0].empcode;

       fechpfdata(emp_id)
      

     })
     .catch(error=> console.log(error));

   }

    function fechpfdata(emp_id)
    {
        axios.get(basurl+`/api/Fetchpfdata/${emp_id}`)
        .then(resdat=>{
   
         setEmpmastrpf(resdat.data);


          console.log(resdat.data);

        
          let aser=resdat.data;

          //console.log(aser);

          for (let i = 0; i < aser.length; i++) {
            sum += aser[i].amount ;

            anualseley+=aser[i].amount*12;
          }

           console.log(sum);


    
                 
        })
 
        
        .catch(error=> console.log(error));
 

    }

    const downloadPDF = () => {

      
        generateAndSavePDF(document.getElementById('pdf-content'), `APPOINTMENTLETTER${Empid.Empid}.pdf`)
    }

        
      
  return (
    <>
    <Headerpart/>

    <main id="main" className="main">

    <section className="section">
      <div className="row">
        <div className="col-lg-12">

          <div className="card">
            <div className="card-body"  id='pdf-content'>
            <div className='col-lg-10 ms-lg-5 p-5'>
            <div  style={{with:"100%"}}>
             
             <img  style={{width:"300px", marginLeft:"-25px"}} src='/Images/logo.png' />
             </div>

            <div class="me-1 border-dark "  >
         <div class="row ">
             <center>
                 <h3 ><u>APPOINTMENT LETTER</u></h3> 

                 
             </center>
         </div> 
     </div> 

     
   

             
           <div class="containe  mt-3">
   
   <div class="row">
       <div class="col-9"> 
       
       <strong>

   
<span className="pcs-customer-name">

<label>Ref: </label>SAINNOV/HR/{moment(employeemaster[0]?.joindate).format('YYYY')}/{moment(employeemaster[0]?.joindate).format('MMMM')}/{moment(employeemaster[0]?.joindate).format('D')}
<br/>
 <label> Name :  </label>
        &nbsp;{employeemaster[0]?.Name}
 <br />
 <label>Address :</label>
 &nbsp;{employeemaster[0]?.Address}
 <br />

 <label>Email:</label>

 &nbsp;{employeemaster[0]?.email}
 
 <br />

 <label>Phone:</label>
 &nbsp;{employeemaster[0]?.contact_numer}
 <br />

</span>
</strong>

</div>
       <div class="col-3"><span class="float-right">Date : {moment(employeemaster[0]?.joindate).format('DD MMMM YYYY')}</span></div>
   </div>

   </div>
  

    <div   style={{with:"100%"}}>

        <p style={{padding:"20px"}}>Dear {employeemaster[0]?.Name}</p>

        <p>We are pleased to confirm that the Board of  {employeemaster[0]?.companyname} has resolved that you be offered a position as a <strong>{employeemaster[0]?.Posin_name}</strong> of the Company. The purpose of

this letter is to confirm the basis of your appointment should you be willing to accept. Could you please sign and return the attached copy in acknowledgement.</p>
               
          <strong>Commencement of employment</strong><br/>
          <p>Your employment will be effective, as of  {moment(employeemaster[0]?.joindate).format('Do MMMM YYYY')}</p>   
          <strong>Job Title</strong>  <br/>
          <p> Your job title will be {employeemaster[0]?.Posin_name} and you will be report to Mr. Subhash Deshmukh (Lead Consultant- HCM & SF)</p>
            <strong>Salary</strong> <br/>
            <p>Your salary and other benefits will be as set out in Schedule I, hereto.</p>

            <strong>Place of joining</strong><br/>
            <p>{employeemaster[0]?.companyaddress}</p>

            <strong>Probation period</strong><br/>
            <p>The first 6 months of your employment will be under a probationary period. During this

period, you are not entitled to take any leave. In case of any emergency special approval to be taken from project manager/CEO/CTO.</p>
<strong>Leave/ Holidays</strong><br/>

<p>On completion of probation and on confirmation you will be entitled to annual leave of 21 days.

All leaves need prior approval from the supervisor/line manager. The company shall notify a list of declared holidays in the beginning of each year.

</p>
<strong>Notice Period</strong><br/>
<p>Employee and Company understand and agree that should Employee terminate employment he/she will give Company ninety (90) days' advance written notice (the "Notice Period"). Company may, at its option, pay Employee for the Notice Period in lieu of active employment during the Notice Period. It is understood that a party's exercise of its rights under this Paragraph shall be without prejudice to any other right or remedy which it may have at law, in equity, or under this Agreement, including,

without limitation, Company's right to terminate such employment without notice for Cause.</p>

<strong>Nature of duties</strong><br></br>

<p>You will perform to the best of your ability all the duties as are inherent in your post and such additional duties as the company may call upon you to perform, from time to time.</p>


<strong>Behavioral policy</strong>
<p>You should be friendly and collaborative. And try not to disrupt the workplace or present obstacles to the colleague’s work.

You must be open for communication with your colleagues, supervisors or team members.</p>

<strong>Company property</strong><br>
</br>
<p>You will always maintain in good condition company property, which may be entrusted to you for official use during the course of your employment and shall return all such property to the company prior to relinquishment of your charge, failing which the cost of the same will be recovered from you by the company.</p>


<strong>Confidential information</strong><br/>

<p>1 . During your employment with the company, you will devote your whole time, attention and skills to the best of your ability for its business. You shall not, directly or indirectly,

engage or associate yourself with, be connected with, concerned, employed or engaged with any other business or activities or any other post or work part time or pursue any course of study whatsoever, without the prior permission of the company.</p>


<p>2 . You must always maintain the highest degree of confidentiality and keep as confidential the records, documents and other confidential information related to the business of the company which may be known to you or confided in you by any means and you will use such records, documents and information only in a duly authorized manner in the interest of the company. For the purpose of this clause “confidential information” means information about the company’s business and that of its customers which is not available to the general public and which may be learnt by you in the course of your employment. This includes, but is not limited to, information related to the organization, its customer’s list, employment policies, personnel, and information about the company’s products, processes including ideas,  concepts, projections, technology, manuals, drawing, designs, specifications, and all papers and resumes, records and other documents containing such confidential information.</p>

<p>3 . At no time, will you remove any confidential information from the office without permission. </p>

<p>4 . Your duty to safeguard and not disclose confidential information will survive expiration or termination of this agreement and/or your employment with the company.</p>

<strong>Applicability of company policy</strong><br/>
<p>The company shall be entitled to make policy declarations from time to time pertaining to

matters like leave entitlement, maternity leave, employee’s benefits, working hours, transfer policies, etc., and may alter the same from time to time and its sole discretion. All such policy decisions of the company shall be binding on you and shall override this Agreement to that extent.</p>

<strong>Acceptance of your appointment letter</strong><br/>
<p>Please confirm your acceptance of this contract of employment by signing and returning the duplicate copy.<br/>We welcome you and look forward to receiving your acceptance and to working with you.</p>


<strong>Best regards,</strong><br/>
<p>Swapnill Sijaria<br/>(Chief Technology Officer)

</p>
<strong> {employeemaster[0]?.companyname}</strong><br/>
        Contact us via email:  <strong>{employeemaster[0]?.compnayemail}</strong><br/>
        Phone Number: <strong>+91-{employeemaster[0]?.compaycontcatnumabe}</strong><br/>
<br/>


<br/>

<br/>

<br/>

<br/>
<br/>
<div class="me-1 border-dark "  style={{marginTop:"30px;"}} >
         <div class="row ">
             <center>
                 <h2 ><u>Schedule I</u></h2> 
             </center>
         </div> 
     </div>
     <strong>1. Employment Grade : BD</strong><br/>
     <strong>2. Designation  :  {employeemaster[0]?.Posin_name} </strong><br/>
     <strong>3. Salary :  {sum}   (  {converter.toWords(sum || 0)} only) per Month</strong><br/>


     <table className="table datatable">

        <thead>
            <tr>
                <th style={{ background:" rgb(49, 61, 79)", color:"#FFF"}}>
                SALAY COMPONENTS
                </th>

                <th style={{ background:" rgb(49, 61, 79)", color:"#FFF"}}>
                AMOUNT IN INR PER MONTH
                </th>
                <th style={{ background:" rgb(49, 61, 79)", color:"#FFF"}}>AMOUNT IN INR PER ANNUM</th>
            </tr>
        </thead>

        <tbody>

            <tr>
                <td  style={{ background:" rgb(172, 184, 201)"}}>TOTAL FIXED PAY</td>
                <td  style={{ background:" rgb(172, 184, 201)"}}> 

                </td>
                <td  style={{ background:" rgb(172, 184, 201)"}}></td>
            </tr>

          
        {empmastrpf && empmastrpf.map((item) => (
        
                  <tr>
                    <td>{item.Pay_name}</td>
                    <td> {item.amount} </td>

                    <td> {item.amount*12} </td>
                    
                  </tr>
                  ))}

        </tbody>

        <tfoot>

<tr  style={{height:"100px", background:" rgb(46, 83, 149)"}}>
                <td style={{ background:" rgb(46, 83, 149)", color:"#FFF"}}>COST TO COMPANY</td>
                <td style={{ background:" rgb(46, 83, 149)", color:"#FFF"}}>   {sum}/-

                </td>
                <td style={{ background:" rgb(46, 83, 149)", color:"#FFF"}}>{anualseley}/-</td>
            </tr></tfoot>

        </table>

        <strong>Swapnill Sijaria</strong><br/>
        <strong>CTO (Chief Technology Officer)</strong>

        <br>
        </br>
        <br>
        </br>


        <strong> {employeemaster[0]?.companyname}</strong><br/>
        Contact us via email:  <strong>{employeemaster[0]?.compnayemail}</strong><br/>
        Phone Number: <strong>+91-{employeemaster[0]?.compaycontcatnumabe}</strong><br/>
        Adress:  <strong>{employeemaster[0]?.companyaddress}</strong><br/>
            </div>
           


            <button
                        style={{
                            backgroundColor: "orangered",
                            border: "none",
                            marginRight: ".5vw",
                             float:"right"
                        }}
                        type="submit"
                        className="btn btn-primary"
                        onClick={downloadPDF}
                    >
                        Dowload Pdf
                    </button>
</div>
           
            </div>
         </div>

        </div>
      </div>
      
   
    </section>


    
        </main>
    </>
  )
}
