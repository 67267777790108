import React, {useEffect,useState} from 'react'

 import { useNavigate } from 'react-router-dom';
 import axios from 'axios';
 import { useParams } from 'react-router-dom';

import moment from 'moment';



import Headerpart from '../Comonpart/Headerpart';
const basurl=process.env.REACT_APP_BASE_URL;
export default function Addemployeetype() {
  const navigate = useNavigate(); 

  
  const [name,setName]=useState('');
  const [editedata ,setEditedata]=useState({});
  const id=useParams() 
  useEffect(() => {
    //const loggedInUser = localStorage.getItem("user");
    if (localStorage.getItem("user")== null) {
      //console.log(loggedInUser);
      navigate('/');
    }
        axios.get(basurl+`/api/getbyEmployeeType/${id.id}`)
        .then(res=>{
            setEditedata(res.data);
            setName(res.data.role.name);
            console.log(res.data.role.name);

        //   console.log(editedate[0].Name);
        })
    // let eserid=Params();
    // console.warn(eserid);
  }, []);
  const handelsubmit=()=>{
   
  
    axios.put(basurl+`/api/updateEmployeeType/${id.id}`,{name})
     
    .then(res => {
      if (res.data) {
        alert("Successfully updated the data");
        setName("")
        setEditedata("")
      } else {
        alert("Something went wrong");
      }
    })
    .catch(err => console.log(err));



}


  return (
    <>
  <Headerpart/>
  <main id="main" className="main">

  <section className="section">
    <div className="row">
      <div className="col-lg-12">

        <div className="card">
          <div className="card-body">


          <h5 className="card-title">Edit EmployeeType   </h5>

                 
                 
<div className="row mb-3">
  <label for="inputEmail3" className="col-sm-2 col-form-label"> Employee Type Name</label>
  <div className="col-sm-10">
  <input
  type="text"
  className="form-control"
  id="inputText"
  value={name}
  onChange={e => setName(e.target.value)}
/>
  </div>


  <div className="text-center mt-3">

<button type="submit" onClick={handelsubmit} className="btn btn-primary">Submit</button>
 
</div>
</div>
           
           
           
           
          </div>
        </div>

      </div>
    </div>
  </section>

      </main>

  </>
  
)
}
