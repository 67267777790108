import React, { useState,useEffect } from 'react'
 import axios from 'axios';
 
  import { useNavigate } from 'react-router-dom';
  
  const basurl=process.env.REACT_APP_BASE_URL;
   

 

export default function Login() {

  
  const navigate = useNavigate();

  const [email,setEmail]=useState('');
   const [passqoed ,setPassword]=useState('');
   const [lat, setLet]=useState();

   const [log, setLog]=useState();


   useEffect(() => {
  
    navigator.geolocation.getCurrentPosition((posistio)=>{

      setLet(posistio.coords.latitude);
      setLog (posistio.coords.longitude);
     
    })
  }, []);


  function handelsubmit(event){
    event.preventDefault()
    axios.post(basurl+'/api/login',{email,passqoed,lat,log})
    .then(res=>{
        if(res.data.Login)
        {

          // console.log(res.data.username);
           console.log(res.data.Alldate);
           
          localStorage.setItem('user', res.data.username);
          localStorage.setItem('userAtutc', res.data.Userid);
          localStorage.setItem('Token', res.data.Token);

          localStorage.setItem('Userrole', res.data.USerrols);
          localStorage.setItem('Alldate', res.data.Alldate);

          localStorage.setItem('Alluser', res.data.Alluser);
          localStorage.setItem('companyname', res.data.companyname);

          
          


            navigate("/Dashbord")
        }
        else
        {
            alert("No Recod Found");
        }
    })
    .catch(err=>console.log(err));
}
  return (
    <>
    <main>
    <div class="container">

      <section class="section register min-vh-100 d-flex flex-column align-items-center justify-content-center py-4">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-lg-4 col-md-6 d-flex flex-column align-items-center justify-content-center">

              <div class="d-flex justify-content-center py-4">
                <a href="index.html" class="logo d-flex align-items-center w-auto">
                  <img src="assets/img/logo.png" alt=""/>
                  <span class="d-none d-lg-block">Login</span>
                </a>
              </div>

              <div class="card mb-3">

                <div class="card-body">

                  <div class="pt-4 pb-2">
                    <h5 class="card-title text-center pb-0 fs-4">Login to Your Account</h5>
                    <p class="text-center small">Enter your username & password to login</p>
                  </div>
                  

                  <form class="row g-3 needs-validation" novalidate  onSubmit={(e) => { handelsubmit(e); }}>

                    <div class="col-12">
                      <label for="yourUsername" class="form-label">Username</label>
                      <div class="input-group has-validation">
                        <span class="input-group-text" id="inputGroupPrepend">@</span>
                        <input type="text" name="username" class="form-control" id="yourUsername" onChange={e=>setEmail(e.target.value)}  required/>
                        <div class="invalid-feedback">Please enter your username.</div>
                      </div>
                    </div>

                    <div class="col-12">
                      <label for="yourPassword" class="form-label">Password</label>
                      <input type="password" name="password" class="form-control" id="yourPassword" onChange={e=>setPassword(e.target.value)}  required/>
                      <div class="invalid-feedback">Please enter your password!</div>
                    </div>

                    
                    <div class="col-12">
                      <button class="btn btn-primary w-100" type="submit">Login</button>
                    </div>
                
                  </form>

                </div>
              </div>

              <div class="credits">
              
                Designed by <a href="#">Crimsion System</a>
              </div>

            </div>
          </div>
        </div>

      </section>

    </div>
  </main>
    </>
  )
}
