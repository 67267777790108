import React, { useEffect, useState } from 'react';
import Headerpart from '../Comonpart/Headerpart';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import { utils, writeFile } from 'xlsx';
const basurl=process.env.REACT_APP_BASE_URL;
export default function Addcomment() {
  const navigate = useNavigate();
  const Crateid = localStorage.getItem("userAtutc");
  const [fetchproject, setFetchproject] = useState('');
  const [sttusesdata, setSttusesdata] = useState('');
  const [commentdata, setcommentdata] = useState('');

  const comentid = useParams();
  const projectid = comentid.comentid;
  const rolsels = localStorage.getItem("Userrole");

  function fechststuss() {
    axios.get(basurl+`/api/Fetchststus`)
      .then(res => {
        setSttusesdata(res.data);
      })
      .catch(error => console.log(error));
  }

  function fetchdata() {
    axios.get(basurl+`/api/getalltimesheet`)
      .then(res => {
        setcommentdata(res.data);
      });
  }
    

  function fetchdatcomment() {
    axios.get(basurl+`/api/getalltimesheet`)
      .then(res => {
        if (res.data.length > 0) {
          setFetchproject(res.data);
        }
        else {
          alert("Something is wrong");
        }
      });
  }

  useEffect(() => {
    if (localStorage.getItem("user") == null) {
      navigate('/');
    }
    
    fechststuss();
    
    if (rolsels == "EMP") {
      fetchdatcomment();
    }
     
    fetchdata();
    fetchdatcomment();
    
  }, []);

  const exportToExcel = () => {
    const worksheet = utils.json_to_sheet(commentdata);
    const workbook = utils.book_new();
    utils.book_append_sheet(workbook, worksheet, 'Sheet 1');
    writeFile(workbook, 'comments.xlsx');
  };

  return (
    <>
      <Headerpart />
      <main id="main" className="main">
        <section className="section">
          <div className="row">
            <div className="col-lg-12">
              <button onClick={exportToExcel}>Export to Excel</button>
              <table className="table datatable" style={{ border: '1px solid' }}>
                <thead>
               
                  <tr>
                    <th scope="col">S.No</th>
                    <th scope="col">Comment Date</th>
                    <th scope="col">Working Hours </th>
                    <th scope="col">Status</th>
                    <th scope="col">Comment</th>
                    <th scope="col">Approval Status</th>
                  </tr>
                </thead>
                <tbody>
                  {commentdata && commentdata.map((item, i) => (
                    <tr key={i}>
                      <td>{i + 1}</td>
                      <td>{moment(item.coment_date).format('MMMM Do YYYY')}</td>
                      <td>{item.work_hourse} HR</td>
                      <td>{item.name}</td>
                      <td>{item.Coomet_text}</td>
                      <td>
                        {item.Approval_status === "0" ?
                          <span style={{ background: "red", color: "#fff", padding: "5px" }}>Pending</span>
                          :
                          <span style={{ background: "green", color: "#fff", padding: "5px" }}>Approved</span>
                        }
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </section>
      </main>
    </>
  );
}
